import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEFAULT_ELCI_SPECIALTY_LANG } from '@app/core/constants/Constants';
import { Procedure } from '@app/core/models/input/procedure/procedure.model';
import { EnvironmentService } from '@app/environment.service';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService {

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private uSrv: UtilsService,
    private loginService: LoginService,
  ) { }

  getProcedure(id: string): Observable<Procedure> {
    return this.http.get<Procedure>(`${this.env.apiUrl}/consents/procedures/code/`+id);
  }

  getProcedures(specialtyId: string, language = DEFAULT_ELCI_SPECIALTY_LANG): Observable<Procedure[]> {
    return this.http.get<Procedure[]>(`${this.env.apiUrl}/consents/procedures/specialty/`+specialtyId+"?"+this.uSrv.genParameterInUri("language", language));
  }

  // Only sudo. If is not sudo will return an ElciException
  getProceduresByCountry(specialtyId: string, country: string, language = DEFAULT_ELCI_SPECIALTY_LANG,): Observable<Procedure[]> {
    return this.http.get<Procedure[]>(`${this.env.apiUrl}/consents/procedures/specialty/`+specialtyId+"?"+this.uSrv.genParameterInUri("language", language)+"&"+this.uSrv.genParameterInUri("country", country));
  }

  getTopProcedures(userUuid: string): Observable<Procedure[]> {
    return this.http.get<Procedure[]>(`${this.env.apiUrl}/users/${userUuid}/topProcedures/doctor`);
  }

  getProcedureWithImagesByCode(code: string, language = DEFAULT_ELCI_SPECIALTY_LANG) {
    const userLanguage = this.loginService.userValue?.language?.locale?.substring(0,2) ?? DEFAULT_ELCI_SPECIALTY_LANG;
    return this.http.get<Procedure>(`${this.env.apiConsents}/procedures/code/${code}/${userLanguage}/${language}/images`);
  }

}
