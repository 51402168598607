import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DESC } from '@app/core/constants/Constants';
import RoleEnum from '@app/core/enums/role.enum';
import UserStatusEnum from '@app/core/enums/user-status.enum';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { Status } from '@app/core/models/input/common/status.model';
import { Page } from '@app/core/models/input/page/pages.model';
import { Role } from '@app/core/models/input/role.model';
import { User } from '@app/core/models/input/user.model';
import { UserService } from '@app/core/services/user/user.service';
import { FilterUtils } from '@app/core/utils/filter.utils';
import { SorterUtils } from '@app/core/utils/sorter.utils';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list-administratives',
  templateUrl: './list-administratives.component.html',
  styleUrls: ['./list-administratives.component.scss']
})
export class ListAdministrativesComponent implements OnInit{
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;


  pagination = new Pagination();
  filters = new Array<Filter>();
  sorters = new Sorters('creationDate', DESC);
  admins?: User[]; 
  statuses?: Status[];
  selectedStatus = 0;

  // Utils
  filterUtils: FilterUtils;
  sorterUtils: SorterUtils;


  // enum
  userStatusEnum = UserStatusEnum;
  roleEnum = RoleEnum;

  nameFilter = new Filter();
  nameFilterStr?: string;
  page?: Page;

 
  companyUuid?:string;

  private roleLevels = new Map<string, string>([
    ['4', 'Advo. básico'],
    ['4,5', 'Advo. ver CIs'],
    ['4,6', 'Supervisor'],
    ['4,5,6', 'Supervisor']
  ]);

  constructor( private userService: UserService,
               private activatedRoute: ActivatedRoute,
              ) {
              this.filterUtils = new FilterUtils(this.filters);
              this.sorterUtils = new SorterUtils(this.sorters);
              this.activatedRoute.params.subscribe(param => {
                this.companyUuid = param['companyUuid'];
                });
  }
  ngOnInit() {
    this.getUserStatus(); 
    this.filterUtils.addFilter(
      'rolesId',
      RoleEnum.ADMINISTRATIVE.toString() + ',' +
      RoleEnum.ADMINISTRATIVE_SUPERVISOR.toString() + ',' +
      RoleEnum.ADMINISTRATIVE_VIEW_CIS.toString()     ); 
       
    if(this.companyUuid !== undefined){     
      this.filterUtils.addFilter('companyUuid', this.companyUuid)
    }
    this.getAdminByCompany();
  }
  addFilter(name: string, filter: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.addFilter(name, filter);
    this.getAdminByCompany();
  }

  removeFilter(name: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.removeFilter(name);
    this.getAdminByCompany();
  }

  addStatusFilter(statusId: string) {  
    // 0 means all statuses
    if (statusId != '0') {
      this.addFilter('userStatusId', statusId);
    } else {
      this.removeFilter('userStatusId');
    }
  }

  paginar(amount: number) {
    this.pagination.page += amount;
    this.getAdminByCompany();
  }

  getAdminByCompany() {
    this.userService
      .getUsers(this.pagination, this.filters, this.sorters)
      .subscribe({
        next: usersPage => {
          this.page = usersPage;
          this.admins = this.page.content;  

        },
      });
  }

  getUserStatus(){
    this.userService.getUserStatus().subscribe({
      next: statuses => {    
        this.statuses = statuses;       
      }
    })
  }
 
  castRole(roles: Role[]):string {    
      // Ordena y convierte los IDs en un string
      const sortedIds = roles.map(role => role.id).sort().join(',');  
      // Retorna el nivel de acceso correspondiente o un valor predeterminado
      return this.roleLevels.get(sortedIds) ?? 'Sin roles definidos';
  } 

  sortAdmins(property: string, direction: string) {    
    this.sorterUtils.sortData(property, direction);  
    this.getAdminByCompany();   
}
}
