import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SuperAdminComponent } from './super-admin.component';
import { CompanyHiringComponent } from './company/pages/company-hiring/company-hiring.component';
import { ListCompaniesComponent } from './company/pages/list-companies/list-companies.component';
import { FileCompanyAdminComponent} from './company/pages/file-company-admin/file-company-admin.component';
import { ListUsersComponent } from './users/pages/list-users/list-users.component';
import { FileUserComponent } from './users/pages/file-user/file-user.component';
import { ListPatientsAdminComponent } from './patients/pages/list-patients-admin/list-patients-admin.component';
import { FilePatientAdminComponent } from './patients/pages/file-patient-admin/file-patient-admin.component';
import { ListSpecialtiesComponent } from './maintenance/pages/list-specialties/list-specialties.component';
import { ListConsentsAdminComponent } from './maintenance/pages/list-consents-admin/list-consents-admin.component';
import { TotalConsentsByDoctorsComponent } from './statistics/pages/total-consents-by-doctors/total-consents-by-doctors.component';
import { ConsentsBySpecialtyComponent } from './statistics/pages/consents-by-specialty/consents-by-specialty.component';
import { ConsentsMostUsedComponent } from './statistics/pages/consents-most-used/consents-most-used.component';
import { DoctorsBySpecialtyComponent } from './statistics/pages/doctors-by-specialty/doctors-by-specialty.component';
import { RgpdsByCompanyComponent } from './statistics/pages/rgpds-by-company/rgpds-by-company.component';
import { DdsByCompanyComponent } from './statistics/pages/dds-by-company/dds-by-company.component';
import { AuthenticationAdminGuard } from '@app/core/guards/admin/authentication-admin.guard';
import { TotalSignsByCompanyComponent } from './statistics/pages/total-signs-by-company/total-signs-by-company.component';
import { TotalApiDocumentsByCompanyComponent } from './statistics/pages/total-api-documents-by-company/total-api-documents-by-company.component';
import { GenConsentsPerSpecialtyComponent } from './maintenance/pages/gen-consents-per-specialty/gen-consents-per-specialty.component';


const routes: Routes = [
  {
    path: '',
    component: SuperAdminComponent,
    canActivate: [AuthenticationAdminGuard],
    children: [
      {
        path: 'register',
        canActivate: [AuthenticationAdminGuard],
        children: [

          {
            path: 'company',
            loadChildren: () =>
            import('./company/company-admin.module').then(m => m.CompanyAdminModule),
            component: CompanyHiringComponent,
            canActivate: [AuthenticationAdminGuard],
          },
        ],
      },
      {
        path: 'companies',
        canActivate: [AuthenticationAdminGuard],
        children: [
          {
            path:'',
            loadChildren: () =>
            import('./company/company-admin.module').then(m => m.CompanyAdminModule),
            component: ListCompaniesComponent,
          },
          {
            path: ':companyUuid',
            loadChildren: () =>
            import('./company/company-admin.module').then(m => m.CompanyAdminModule),
            component: FileCompanyAdminComponent,
            canActivate: [AuthenticationAdminGuard],
          },
          {
            path: ':companyUuid/users',
            loadChildren: () =>
              import('./users/user.module').then(m => m.UserModule),
              component: ListUsersComponent,
              canActivate: [AuthenticationAdminGuard],
              data: {showProfessionals: true, showAdmins: true}
          },
        ]
      },
      {
        path: 'users',
        canActivate: [AuthenticationAdminGuard],
        children: [
          {
            path:'',
            loadChildren: () =>
            import('./users/user.module').then(m => m.UserModule),
            component: ListUsersComponent,
            canActivate: [AuthenticationAdminGuard],
            data: {showProfessionals: true, showAdmins: true}
          },
          {
            path:'professionals',
            canActivate: [AuthenticationAdminGuard],
            children: [
              {
                path:'',
                loadChildren: () =>
                import('./users/user.module').then(m => m.UserModule),
                component: ListUsersComponent,
                canActivate: [AuthenticationAdminGuard],
                data: {showProfessionals: true},
              },
              {
                path: ':companyUuid/file-doctor/:uuid',
                canActivate: [AuthenticationAdminGuard],
                children: [
                  {
                    path: ':tab',
                    canActivate: [AuthenticationAdminGuard],
                    children: [
                      {
                        path: '',
                        loadChildren: () =>
                        import('./users/user.module').then(m => m.UserModule),
                        component: FileUserComponent,
                        canActivate: [AuthenticationAdminGuard],
                      },
                    ],
                  },
                ],
              },
            ]
          },
          {
            path:'admins',
            canActivate: [AuthenticationAdminGuard],
            children: [
              {
                path:'',
                loadChildren: () =>
                import('./users/user.module').then(m => m.UserModule),
                component: ListUsersComponent,
                canActivate: [AuthenticationAdminGuard],
                data:{showAdmins: true}
              },
              {
                path: ':companyUuid/file-admin/:uuid',
                loadChildren: () =>
                import('./users/user.module').then(m => m.UserModule),
                component: FileUserComponent,
                canActivate: [AuthenticationAdminGuard],
              }
            ]
          },
        ]
      },

      {
        path: 'patients',
        canActivate: [AuthenticationAdminGuard],
        children: [
          {
            path: '',
            loadChildren: () =>
            import('./patients/patients.module').then(m => m.PatientsAdminModule),
            component: ListPatientsAdminComponent,
            canActivate: [AuthenticationAdminGuard],
          },
          {
            path: ':uuid',
            canActivate: [AuthenticationAdminGuard],
            children: [
              {
                path: ':tab',
                canActivate: [AuthenticationAdminGuard],
                children: [
                  {
                    path: '',
                    loadChildren: () =>
                    import('./patients/patients.module').then(m => m.PatientsAdminModule),
                    component: FilePatientAdminComponent,
                    canActivate: [AuthenticationAdminGuard],
                  }
                ]
              }
            ]
          },
        ]
      },
      {
        path: 'maintenance',
        canActivate: [AuthenticationAdminGuard],
        children: [
          {
            path: 'specialties',
            loadChildren: () =>
            import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
            component: ListSpecialtiesComponent,
            canActivate: [AuthenticationAdminGuard],
          },
          {
            path: 'consents',
            loadChildren: () =>
            import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
            component: ListConsentsAdminComponent,
            canActivate: [AuthenticationAdminGuard],
          },
          {
            path: 'consentsperspecialty',
            loadChildren: () =>
            import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
            component: GenConsentsPerSpecialtyComponent,
            canActivate: [AuthenticationAdminGuard],
          },
        ]
      },
      {
        path: 'statistics',
        canActivate: [AuthenticationAdminGuard],
        children: [
          {
            path: 'consents-by-doctors',
            loadChildren: () =>
            import('./statistics/statistics.module').then(m => m.StatisticsModule),
            component: TotalConsentsByDoctorsComponent,
            canActivate: [AuthenticationAdminGuard],
          },
          {
            path: 'consents-by-specialty',
            loadChildren: () =>
            import('./statistics/statistics.module').then(m => m.StatisticsModule),
            component: ConsentsBySpecialtyComponent,
            canActivate: [AuthenticationAdminGuard],
          },
          {
            path: 'consents-most-used',
            loadChildren: () =>
            import('./statistics/statistics.module').then(m => m.StatisticsModule),
            component: ConsentsMostUsedComponent,
            canActivate: [AuthenticationAdminGuard],
          },
          {
            path: 'doctors-by-specialty',
            loadChildren: () =>
            import('./statistics/statistics.module').then(m => m.StatisticsModule),
            component: DoctorsBySpecialtyComponent,
            canActivate: [AuthenticationAdminGuard],
          },
          {
            path: 'rgpds-by-company',
            loadChildren: () =>
            import('./statistics/statistics.module').then(m => m.StatisticsModule),
            component: RgpdsByCompanyComponent,
            canActivate: [AuthenticationAdminGuard],
          },
          {
            path: 'dds-by-company',
            loadChildren: () =>
            import('./statistics/statistics.module').then(m => m.StatisticsModule),
            component: DdsByCompanyComponent,
            canActivate: [AuthenticationAdminGuard],
          },
          {
            path: 'signs-by-company',
            loadChildren: () =>
            import('./statistics/statistics.module').then(m => m.StatisticsModule),
            component: TotalSignsByCompanyComponent,
            canActivate: [AuthenticationAdminGuard],
          },
          {
            path: 'api-documents-by-company',
            loadChildren: () =>
            import('./statistics/statistics.module').then(m => m.StatisticsModule),
            component: TotalApiDocumentsByCompanyComponent,
            canActivate: [AuthenticationAdminGuard],
          },
        ]
      }
    ]
  }
]


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuperAdminRoutingModule {}
