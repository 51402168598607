<form [formGroup]="dataGenConsent" class="dataconsent">
  <div class="generate_doc">
    <div class="input input_date">
      <label for="deliveryDate" class="deliveryDate"> {{ 'PRIVATE.PATIENT.GENERATE-DOC.DELIVERY-DATE' | translate }}</label>
      <input type="date" formControlName="deliveryDate" id="deliveryDate" [ngClass]="{'wrong': shouldShowError('deliveryDate')}" [min]="todayDate" >
  </div> 
  <p class="message_error wrong-text" *ngIf="this.dataGenConsent.get('deliveryDate')?.errors?.['required'] && this.isOnSubmit">{{"PRIVATE.PATIENT.GENERATE-DOC.DELIVERY-DATE-REQUIRED" | translate }}</p>
  <p class="message_error wrong-text" *ngIf="this.dataGenConsent.get('deliveryDate')?.hasError('noPastDate')">{{ "PRIVATE.PATIENT.GENERATE-DOC.DELIVERY-DATE-ERROR-TODAY-OR-BEFORE" | translate }}</p>
    <p class="subtitle">
      {{ 'PRIVATE.PATIENT.GENERATE-DOC.TITLE' | translate }}
    </p>

    <div class="sign_type">
      <button 
        class="button tipo-white-color back-blue-color button_bio tooltip"
        [ngClass]="{
          button_selected:selectedOutputType === OutputTypeEnum.DIGITAL_VID && isBiometric,
          inactive: isPaper && !isBiometric 
        }"
        (click)="changeOutputType(OutputTypeEnum.DIGITAL_VID, isBiometric)">
        <fa-icon [icon]="faTabletScreenButton" class="icono"></fa-icon>
        {{ 'PRIVATE.PATIENT.GENERATE-DOC.DIGITAL' | translate }}
        <p class="small red text_tip" *ngIf="!isBiometric">{{"PRIVATE.CONSENTS.GENERATE-CONSENT.FORM-MESSAGES.DIGITAL-SUBSCRIPTION" | translate}}</p>
      </button>
      <button
        class="button tipo-white-color back-blue-color tooltip"
        [ngClass]="{ button_selected:selectedOutputType === OutputTypeEnum.DIGITAL_REMOTE && isRemote, inactive: !isRemote }"
        (click)="changeOutputType(OutputTypeEnum.DIGITAL_REMOTE, isRemote)">
        <fa-icon [icon]="faEnvelopeCircleCheck" class="icono"></fa-icon>
        {{ 'PRIVATE.PATIENT.GENERATE-DOC.REMOTE' | translate }}
        <p class="small red text_tip" *ngIf="!isRemote">{{"PRIVATE.CONSENTS.GENERATE-CONSENT.FORM-MESSAGES.REMOTE-SUBSCRIPTION" | translate}}</p>
      </button>
      <button
        class="button tipo-white-color back-blue-color button_paper tooltip"
        [ngClass]="{ button_selected:selectedOutputType === OutputTypeEnum.PAPER || (isPaper && !isRemote && !isBiometric) }"
        (click)="changeOutputType(OutputTypeEnum.PAPER, isPaper)">
        <fa-icon [icon]="faPrint" class="icono"></fa-icon>
        {{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.PAPER' | translate }}
      </button>
      
      
    </div>
    <div class="digital">
      <select
        formControlName="device"
        name="device"
        id="device"
        *ngIf="selectedOutputType === OutputTypeEnum.DIGITAL_VID && isBiometric">
        <option value="0">
          {{ 'PRIVATE.PATIENT.GENERATE-DOC.SELECT' | translate }}
        </option>
        <option *ngFor="let device of devices; let i = index" [value]="device.deviceName">
          {{ device.deviceDescription }}
        </option>
      </select>
      <p
        class="message_error wrong-text"
        *ngIf="this.dataGenConsent.get('device')?.errors?.['notEqualToZero'] && isOnSubmit">
        {{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.FORM-MESSAGES.DEVICE-REQUIRED' | translate }}
      </p>
      <div class="input send">
        <input
          type="checkbox"
          formControlName="isSendEmailChecked"
          id="isSendEmailChecked"
          [disabled]="!(consent?.patient?.email)"
        />
        <label
          for="send_client"
          id="label_send_client"
          [ngClass]="{
            isSendEmailChecked: isSendEmailChecked,
            'label-disabled': !(consent?.patient?.email)
          }"
          (click)="!(consent?.patient?.email) ? $event.preventDefault() : changeSendClientValue()"
        >
          {{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.SEND-CLIENT' | translate }}
        </label>
      </div>
      <div class="input_check input_row_check">
        <input
          type="checkbox"
          formControlName="isCheckedSendEmailBeforeSign"
          id="isCheckedSendEmailBeforeSign"
          [disabled]="!(consent?.patient?.email)"
        />
        <label
          for="sendBeforeSign"
          id="sendEmailBeforeSign_label"
          [ngClass]="{
            isCheckedSendEmailBeforeSign: isCheckedSendEmailBeforeSign,
            'label-disabled': !(consent?.patient?.email)
          }"
          (click)="!(consent?.patient?.email) ? $event.preventDefault() : checkedSendEmailBeforeSign()"
        >
          {{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.SEND-EMAIL-BEFORE-SIGN' | translate }}
        </label>
        <div class="tooltip tooltip_float" *ngIf="(consent?.patient?.email)">
          <fa-icon
            [icon]="faCircleQuestion"
            class="icono tipo-second-color ico_tool"></fa-icon>
          <p class="text_tip_check small">
            {{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.SEND-EMAIL-BEFORE-SIGN-EXPLANATION' | translate }}
          </p>
        </div>
        <div class="tooltip tooltip_float" *ngIf="!(consent?.patient?.email)">
          <fa-icon
            [icon]="faCircleQuestion"
            class="icono tipo-second-color ico_tool"></fa-icon>
          <p class="text_tip_check small">
            {{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.CHECK-BOX-DISABLED-NO-EMAIL' | translate }}
          </p>
        </div>
      </div>
      <!--Aparece si el paciente tiene 2 representantes legales  -->
      <div *ngIf="(isBiometric || isRemote) && (selectedOutputType === OutputTypeEnum.DIGITAL_VID || selectedOutputType === OutputTypeEnum.DIGITAL_REMOTE)">
        <app-legal-representatives-signs></app-legal-representatives-signs>
      </div>
      
    </div>
    <div class="remote" *ngIf="selectedOutputType === OutputTypeEnum.DIGITAL_REMOTE && isRemote">
      <p
        class="message_error wrong-text"
        *ngIf="shouldShowError('patientEmail') || shouldShowError('patientPhone')  || shouldShowError('firstRepresentativeEmail') || shouldShowError('firstRepresentativePhone') || shouldShowError('secondRepresentativeEmail') || shouldShowError('secondRepresentativePhone')">
        {{"PRIVATE.CONSENTS.GENERATE-CONSENT.FORM-MESSAGES.MOVIL-MAIL-REQUIRED" | translate}}
      </p>
      <div class="input_row">
        <input
          type="email"
          name="email"
          id="email"
          [ngClass]="{ wrong: shouldShowError('patientEmail') }"
          formControlName="patientEmail"
          *ngIf="numberOfRepresentatives() === 0"
          placeholder="{{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.EMAIL-PATIENT' | translate }}" />
          <div class="input_row movil_row" *ngIf="numberOfRepresentatives() === 0">
            <select formControlName="phonePrefix" class="phonePrefix" >                   
              <option *ngFor= "let prefix of phonePrefixes" value="{{prefix.countryCode}}" >{{prefix.countryCode}}</option>
            </select> 
            <input
              type="text"
              name="phone"
              class="phone"
              formControlName="patientPhone"              
              placeholder="{{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.PHONE-PATIENT' | translate }}" />
         </div>     
      </div>

      <!-- Aparece si hay representantes legales -->
      <div class="input_row">
        <input
          type="email"
          name="email"
          id="email"
          formControlName="firstRepresentativeEmail"
          *ngIf="numberOfRepresentatives() === 1 || numberOfRepresentatives() === 2"
          placeholder="{{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.EMAIL-RL1' | translate }}" />
          <div class="input_row movil_row" *ngIf="numberOfRepresentatives() === 1 || numberOfRepresentatives() === 2">
            <select formControlName="phonePrefix1" class="phonePrefix" >                   
              <option *ngFor= "let prefix of phonePrefixes" value="{{prefix.countryCode}}" >{{prefix.countryCode}}</option>
            </select> 
            <input
            type="text"
            name="phone"
            class="phone"
            formControlName="firstRepresentativePhone"
            
            placeholder="{{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.PHONE-RL1' | translate }}" />
          </div> 
        </div>
      <div class="input_row">
        <input
          type="email"
          name="email"
          id="email"
          formControlName="secondRepresentativeEmail"
          *ngIf="numberOfRepresentatives() === 2"
          placeholder="{{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.EMAIL-RL2' | translate }}" />
          <div class="input_row movil_row" *ngIf="numberOfRepresentatives() === 2">
            <select formControlName="phonePrefix2" class="phonePrefix" >                   
              <option *ngFor= "let prefix of phonePrefixes" value="{{prefix.countryCode}}" >{{prefix.countryCode}}</option>
            </select> 
            <input
              type="text"
              name="phone"
              class="phone"
              formControlName="secondRepresentativePhone"
              
              placeholder="{{ 'PRIVATE.CONSENTS.GENERATE-CONSENT.PHONE-RL2' | translate }}" />
          </div>
      </div>
    </div>
    <div class="buttons">
      <button class="button tipo-white-color back-blue-color" *ngIf="ownSubmit" (click)="submitSendToPatient()">
        <fa-icon [icon]="faPaperPlane" class="icono"></fa-icon>
        {{ 'PRIVATE.CONSENTS.EXPIRED-CONSENT.BUTTON-SEND' | translate }}
      </button>
    </div>
  </div>
</form>
