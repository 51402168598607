import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConvertOutputDTO } from '@app/core/models/converter.model';
import { ConvertInputModel } from '@app/core/models/input/convert-input.model';
import { EnvironmentService } from '@app/environment.service';

@Injectable({
  providedIn: 'root'
})
export class HtmlToPdfService {

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
  ) { }

  convertHtmlToPdf(converterInput: ConvertInputModel) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<ConvertOutputDTO>(
      `${this.env.apiUrl}/convert`,
      JSON.stringify(converterInput),
      { headers: headers }
    );
  }

}
