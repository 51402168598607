<div class="wrapper">
  <h4 class="title tipo-second-color bold" id="title_table">
    {{ 'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.TITLE-GEN-CONSENTS' | translate }}
  </h4>
  <div class="box_container">
    <div class="box box_data">
      <!-- Añado un contenedor flex para alinear el formulario y el progreso circular -->
      <div class="form-progress-container">
        <form [formGroup]="form" class="formdata">
          <div class="input_row select-patient">
            <label for="company" class="company">
              {{ 'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.SELECT-COMPANY' | translate }}
            </label>
            <div class="input back-light-blue inlineflex input2" dir="ltr">
              <input
                #companyName
                name="companyName"
                id="companyName"
                (debounceInput)="getCompanies(companyName.value)"
                [appDebounceInput]="300"
                type="text"
                formControlName="companyName"
                placeholder="Selecciona una compañía {{ 'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.SELECT-COMPANY' | translate }}" />
              <fa-icon [icon]="faAngleDown" class="icono tipo-second-color arrow"></fa-icon>
              <ul *ngIf="showDropdown" class="dropdown-list">
                <li
                  [value]="company"
                  *ngFor="let company of companies; let i = index"
                  (click)="setSelectedCompany(company)"
                  onKeyDown="setSelectedCompany(company)">
                  {{ company.businessName }}
                </li>
              </ul>
            </div>
          </div>
          <div class="input">
            <label for="language" class="language">
              {{ 'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.SELECT-LANGUAGE' | translate }}
            </label>
            <select formControlName="language" id="language">
              .
              <option value="0">{{ 'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.SELECT-LANGUAGE' | translate }}</option>
              <option *ngFor="let language of languages" [ngValue]="language.locale">
                {{ language.name }}
              </option>
            </select>
          </div>
          <div class="input">
            <label for="template" class="template">
              {{ 'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.SELECT-TEMPLATE' | translate }}
            </label>
            <select formControlName="template" id="template">
              <option value="0">Selecciona una template</option>
              <option *ngFor="let template of templates" [ngValue]="template.uuid">
                {{ template.type }}
              </option>
            </select>
          </div>
          <textarea
            #logContainer
            style="resize: none"
            readonly
            [value]="logContent"
            rows="15"
            name="document"
            id="document"></textarea>

          <div class="buttons">
            <button
              type="button"
              class="button tipo-white-color back-blue-color"
              [ngClass]="{ 'back-blue-color': isTemplateSelected && isLanguageSelected && isCompanySelected }"
              (click)="processConsents()"
              [disabled]="!isTemplateSelected && !isLanguageSelected && !isCompanySelected">
              <fa-icon
                [icon]="faSpinner"
                class="icono iconono"
                animation="spin-pulse"
                *ngIf="isProcessInitiated"></fa-icon>
              {{ 'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.BUTTON-GENERATE' | translate }}
            </button>
            <button
              type="button"
              class="button tipo-white-color back-blue-color"
              [ngClass]="{ 'back-blue-color': processFinished }"
              (click)="downloadZip()"
              [disabled]="!processFinished && !content">
              {{ 'SUPER-ADMIN.GEN-CONSENTS-PER-SPECIALITY.BUTTON-DOWNLOAD' | translate }}
            </button>
          </div>
        </form>
      </div>

      <ng-container *ngIf="loadCiTemplate">
        <app-ci-template [user]="decoyUser" [consent]="consent" style="display: none"></app-ci-template>
      </ng-container>
    </div>
  </div>
</div>
