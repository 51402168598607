import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SafeHtmlPipe } from '@app/core/pipes/safe-html/safe-html.pipe';
import { UTCToLocalPipe } from '@app/core/pipes/utc-to-local/utc-to-local.pipe';
import { YesNoPipe } from '@app/core/pipes/yes-no/yes-no.pipe';
import { HasRoleDirective } from '@app/shared/directives/if-role.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { DebounceInputDirective } from './directives/debounce-input.directive';
import { TranslateCustomService } from './services/translate/translate.service';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ToTopComponent } from './components/to-top/to-top.component';
import { ModalComponent } from './components/modal/modal.component';



@NgModule({
  declarations: [
    HasRoleDirective,
    YesNoPipe,
    SafeHtmlPipe,
    UTCToLocalPipe,
    DebounceInputDirective,
    PaginatorComponent,
    ToTopComponent,
    ModalComponent
  ],
  imports: [CommonModule, TranslateModule, RouterModule, FontAwesomeModule],
  providers: [TranslateCustomService],
  exports: [
    TranslateModule,
    RouterModule,
    FontAwesomeModule,
    HasRoleDirective,
    YesNoPipe,
    SafeHtmlPipe,
    UTCToLocalPipe,
    DebounceInputDirective,
    PaginatorComponent,
    ToTopComponent,
    ModalComponent
  ],
})
export class SharedModule {}
