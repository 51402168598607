<div class="window_error box box1">
    <fa-icon [icon]="faCircleXmark" class="icono tipo-second-color icon_close" (click)="closeWindow()"></fa-icon>
    <h4 class="title tipo-second-color bold">{{ "PRIVATE.WINDOW-ERROR.TITLE" | translate}} <span class="id_document">{{downloadUuid}}7</span></h4>
    
    <p>{{ "PRIVATE.WINDOW-ERROR.STATE" | translate }} <span class="state red">{{errorType}}</span></p>
    <p>{{ "PRIVATE.WINDOW-ERROR.ERROR-MENSAGE" | translate }} <span class="mesage red">{{messageError}}</span></p>
    <p>{{ "PRIVATE.WINDOW-ERROR.DATE" | translate }} <span class="date">{{dateError | date : 'dd/MM/YYYY'}}</span></p>
    <div *ngFor="let signer of signers, let i= index">
      <p>{{ "PRIVATE.WINDOW-ERROR.SIGNER" | translate }} <span class="signer">{{signer.name}}</span></p>
      <p>{{ "PRIVATE.WINDOW-ERROR.IDENTIFY" | translate }} <span class="id_signer">{{signer.uuid}}</span></p>
  </div>    
  </div>
