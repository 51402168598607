import { Component, Output, EventEmitter, Input } from '@angular/core';
import { GENERATE_CIS } from '@app/core/constants/Permissions';
import { faFileCirclePlus, faFileContract, faTrashCan, faRotate } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-buttons-consent',
  templateUrl: './buttons-consent.component.html'
})
export class ButtonsConsentComponent {
  faFileCirclePlus = faFileCirclePlus;
  faFileContract = faFileContract;
  faTrashCan = faTrashCan;
  faRotate = faRotate;

  GENERATE_CIS = GENERATE_CIS;

  @Output() buttonClick = new EventEmitter<void>();
  @Input() refreshingConsents = false;

  refreshOnParent() {
    this.refreshingConsents = false;
    this.buttonClick.emit();
  }


}
