<div class="wrapper">
    <div class="maxwidth" >
        <h4 class="title tipo-second-color bold">{{ "PRIVATE.PERSONALIZATION.DEVICE-DOCUMENTS.TITLE" | translate}}<span> {{deviceName}}</span></h4>
        <div class="box_container">
            <div *ngIf="documentsDevice?.length !== 0">
                <div class="box box2" *ngFor="let item of documentsDevice; let i=index">
                    <p class="regular">{{ "PRIVATE.PERSONALIZATION.DEVICE-DOCUMENTS.SUBTITLE-ONE" | translate }}
                        <span class="tipo-second-color bold">{{item.fileName}}</span></p>
                    <p class="regular">{{ "PRIVATE.PERSONALIZATION.DEVICE-DOCUMENTS.SUBTITLE-TWO" | translate }}  {{item.docGUI}}</p>
                    <p class="regular">{{ "PRIVATE.PERSONALIZATION.DEVICE-DOCUMENTS.SUBTITLE-THREE" | translate }}  {{item.creationDate}}</p>
                    <div class="buttons">
                        <button class="button tipo-white-color back-blue-color" (click)="deleteDocumentInDevice(item.docGUI)">
                            <fa-icon [icon]="faTrashCan" class="icono"></fa-icon>
                        {{ "PRIVATE.PERSONALIZATION.DEVICE-DOCUMENTS.BUTTON-DELETE" | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="box box2 box_nodocs nodocuments" *ngIf="documentsDevice?.length === 0">
                <p class="regular center ">{{ "PRIVATE.PERSONALIZATION.DEVICE-DOCUMENTS.TITLE-NO-DOCUMENTS" | translate }}</p>
                <fa-icon [icon]="faLaptopFile" class="icono tipo-second-color iconono"></fa-icon>
            </div>
        </div>


    </div>
</div>