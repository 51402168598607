import { Component, OnInit } from '@angular/core';
import { DESC } from '@app/core/constants/Constants';
import { COMPANY_ROLES, DOCTOR_ROLES } from '@app/core/constants/Permissions';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { Page } from '@app/core/models/input/page/pages.model';
import { Patient } from '@app/core/models/input/patient/patient.model';
import { PatientService } from '@app/core/services/patient/patient.service';
import { RepresentativePatientDTO } from '@app/core/models/input/patient/representative.model';
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faDownload,
  faFileCirclePlus,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import { FilterUtils } from '@app/core/utils/filter.utils';

@Component({
  selector: 'app-list-patients',
  templateUrl: './list-patients.component.html',
  styleUrls: ['./list-patients.component.scss'],
})
export class ListPatientsComponent implements OnInit {
  faFileCirclePlus = faFileCirclePlus;
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;
  faDownload = faDownload;
  faUserPlus = faUserPlus;

  DOCTOR_ROLES = DOCTOR_ROLES;
  COMPANY_ROLES = COMPANY_ROLES;

  pagination = new Pagination();
  filters = new Array<Filter>();
  sorters = new Sorters('creationDate', DESC);

  patients?: Patient[];
  page?: Page;
  isCheckedRls = false;
  searchNameFor= 'fullName';
  searchDocumentFor = 'documentNumber';

  // Utils
  filterUtils: FilterUtils;

  nameFilter = new Filter();
  nameFilterStr?: string;

  constructor(
    private patientService: PatientService   
  ) {
    this.filterUtils = new FilterUtils(this.filters);
  }

  ngOnInit(): void {
    this.getPatients();
  }

  paginar(amount: number) {
    this.pagination.page += amount;
    this.getPatients();
  }


  addFilter(name: string, filter: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.addFilter(name, filter);
    this.getPatients();
  }

  getPatients() {
    this.patientService
      .getPatients(this.pagination, this.filters, this.sorters)
      .subscribe({
        next: data => {
          this.page = data
          this.patients = data.content;
        },
      });
  }

  getActiveRepresentativePatientsCount(patient: Patient): number {
    if (patient.representativePatients && Array.isArray(patient.representativePatients)) {
      return patient.representativePatients.filter((rp: RepresentativePatientDTO) => rp.active === true).length;
    }
    return 0;
  }


  checkedRls(){
    this.isCheckedRls = !this.isCheckedRls;
    if(this.isCheckedRls) {
      this.filterUtils.removeFilter('fullName')
      this.filterUtils.removeFilter('documentNumber')
      this.searchNameFor = 'fullNameWithReps';
      this.searchDocumentFor = 'documentNumberWithReps';
   
    }
    else{
      this.filterUtils.removeFilter('fullNameWithReps')
      this.filterUtils.removeFilter('documentNumberWithReps')
      this.searchNameFor = 'fullName';
      this.searchDocumentFor = 'documentNumber';
    }
  }
}
