<div class="wrapper">
  <div class="maxwidth">


      <div class="buttons">

              <button class="button tipo-white-color back-blue-color" [routerLink]="['new']">
                      <fa-icon [icon]="faUserPlus" class="icono"></fa-icon>
                      {{ 'PRIVATE.PATIENT.FILE-PATIENT.BUTTON-NEW-PATIENT' | translate }}
              </button>
             <!-- TODO *appHasRole="COMPANY_ROLES"-->
              <button  *ngIf="false" class="button tipo-white-color back-blue-color" >
                      <fa-icon [icon]="faDownload" class="icono"></fa-icon>
                      {{ "PRIVATE.PATIENT.FILE-PATIENT.BUTTON-DOWNLOAD-GDPRS" | translate }}
              </button>

      </div>
      <!-- loader mientras carga la página -->
      <app-loader></app-loader>

      <div class="list">
          <h4 class="title tipo-second-color bold" id="title_table">{{ 'PRIVATE.PATIENT.LIST-PATIENTS.TITLE' | translate}}</h4>
          <table class="table" aria-describedby="title_table">
              <thead class="tipo-second-color bold">
                <th scope="col" class="id">{{'PRIVATE.PATIENT.LIST-PATIENTS.TITLE-ID' | translate}}</th>
                  <th scope="col">
                      <div class="column" >
                          {{ "PRIVATE.PATIENT.LIST-PATIENTS.TITLE-NAME" | translate }}
                          <input type="text" name="filterName" (debounceInput)="addFilter(searchNameFor, filterName.value)" [appDebounceInput]="300" #filterName id="filterName" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                      </div>
                  </th>
                  <th scope="col" class="document">
                      <div class="column">
                          {{ "PRIVATE.PATIENT.LIST-PATIENTS.TITLE-DOCUMENT" | translate}}
                          <input type="text" name="filterDocument" (debounceInput)="addFilter(searchDocumentFor, filterDocument.value)" [appDebounceInput]="300" #filterDocument id="filterDocument" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                      </div>
                  </th>
                  <th scope="col" class="history">
                    <div class="column">
                        {{ "PRIVATE.PATIENT.DATA-PATIENT.CLINIC-HISTORY" | translate}}
                        <input type="text" name="filterHistory" (debounceInput)="addFilter('healthHistoryExt', filterHistory.value)" [appDebounceInput]="300" #filterHistory id="filterHistory" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>
                  <th scope="col" class="date">
                      <div class="column">
                          {{ "PRIVATE.PATIENT.LIST-PATIENTS.TITLE-DATE" | translate}}
                      </div>
                  </th>
                  <th scope="col" class="rl">
                    <div class="column legal ">
                        {{ "PRIVATE.PATIENT.LIST-PATIENTS.TITLE-RLEGAL" | translate }}
                        <div class="tooltip">
                            <label for="searchRls" id="checkrlslabel" 
                            [ngClass]="{'isCheckedRls':isCheckedRls}" 
                            (click)="checkedRls()"
                            ></label>                       
                            <input type="checkbox" name="searchRls" id="checkrls"> 
                            <p class="text_tip small light">{{"PRIVATE.PATIENT.LIST-PATIENTS.FILTER-RLS" | translate}}</p>
                       </div>
                    </div>
                    
                  </th>
                  <th scope="col" class="ci">{{ "PRIVATE.PATIENT.LIST-PATIENTS.TITLE-CIS" | translate }}</th>
                  <th scope="col" class="rgpd">{{ "PRIVATE.PATIENT.LIST-PATIENTS.TITLE-GDPR" | translate}}</th>
                  <th scope="col" class="dd">{{ "PRIVATE.PATIENT.LIST-PATIENTS.TITLE-DDS" | translate }}</th>
              </thead>
              <tbody>
                  <tr [routerLink]="['./'+ patient.uuid + '/file']" *ngFor= "let patient of patients; let i=index">
                      <td class="id">{{patient.externalId ? patient.externalId : patient.id}}</td>
                      <td class="name"><span class="dato">{{patient.name}} {{patient.firstLastName}} {{patient.secondLastName}}</span></td>
                      <td class="document">{{patient.documentNumber}}</td>
                      <td class="history">{{patient.healthHistoryExt}}</td>
                      <td class="date">{{ patient.creationDate }}</td>
                      <td class="rl">{{ getActiveRepresentativePatientsCount(patient) ===  0 ? "" : getActiveRepresentativePatientsCount(patient)}}</td>
                      <td class="ci">
                        <span class="pendiente" *ngIf="patient.consentsStats?.pending !== 0">{{ patient.consentsStats?.pending}}</span>
                        <span class="firmado" *ngIf="patient.consentsStats?.signed !== 0">{{ patient.consentsStats?.signed}}</span>
                        <span class="rechazado" *ngIf="patient.consentsStats?.rejected !== 0">{{ patient.consentsStats?.rejected}}</span>
                        <span class="regular" *ngIf="patient.consentsStats?.draft !== 0">{{ patient.consentsStats?.draft}}</span>
                    </td>
                      <td class="rgpd">
                          <span class="pendiente" *ngIf="patient.rgpdStats?.pending !== 0">{{ patient.rgpdStats?.pending}}</span>
                          <span class="firmado" *ngIf="patient.rgpdStats?.signed !== 0">{{ patient.rgpdStats?.signed}}</span>
                          <span class="rechazado" *ngIf="patient.rgpdStats?.rejected !== 0">{{ patient.rgpdStats?.rejected}}</span>
                      </td>
                      <td class="dd">
                          <span class="pendiente" *ngIf="patient.digitalDocumentsStats?.pending !== 0">{{ patient.digitalDocumentsStats?.pending}}</span>
                          <span class="firmado" *ngIf="patient.digitalDocumentsStats?.signed !== 0">{{ patient.digitalDocumentsStats?.signed}}</span>
                          <span class="rechazado" *ngIf="patient.digitalDocumentsStats?.rejected !== 0">{{ patient.digitalDocumentsStats?.rejected}}</span>
                      </td>
                  </tr>

              </tbody>
          </table>
      <!-- Pagination -->
      <app-paginator
        [totalElements]="page?.totalElements ?? 0"  
        [numbersOfElements]="page?.numberOfElements ?? 0"
        [totalPages]="page?.totalPages ?? 0"
        [pageNumber]="pagination.page"
        [lastPage]="page?.last ?? false"
        (paginate)="paginar($event)"></app-paginator>
      <!-- End of Pagination -->

      </div>