import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response';
import { AlertService } from '@app/core/services/alert/alert.service';
import { LoginService } from '@app/core/services/login/login.service';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private alertService: AlertService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const errorBody: ErrorResponse = !err.error ? new ErrorResponse(err.status) : err.error;
        if ([401, 403].includes(err.status) && this.loginService.userValue) {
          this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
        }
        if (errorBody?.messages) {
          errorBody.messages.forEach(errorMessage => {
            this.alertService.error(errorMessage);
          });
        }

        const error = errorBody?.messages ?? err.statusText;
        return throwError(() => error);
      })
    );
  }
}
