export class ConvertInputModel {
    topMargin?: string;
    bottomMargin?: string;
    htmlHeader?: string;
    htmlFooter?: string;
    htmlBody?: string;

    constructor(topMargin?: string, bottomMargin?: string, htmlHeader?: string, htmlFooter?: string, htmlBody?: string) {
        this.topMargin = topMargin;
        this.bottomMargin = bottomMargin;
        this.htmlHeader = htmlHeader;
        this.htmlFooter = htmlFooter;
        this.htmlBody = htmlBody;
    }

}
