import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_ELCI_LANGUAGE } from '@app/core/constants/Constants';
import {
  ANESTESIA_ADDITIONAL_CLAUSE,
  OPHTHALMOLOGY_ADDITIONAL_CLAUSE,
  SPECIALTIES_ID_WITH_ADDITIONAL_CLAUSE,
} from '@app/core/constants/additional-clauses.constants';
import DigitalCiSignatureSignTypeEnum from '@app/core/enums/digital-ci-signature-sign-type.enum';
import OutputTypeEnum from '@app/core/enums/output-type.enum';
import SurgeryTypeEnum from '@app/core/enums/surgery-type.enum';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { Variable } from '@app/core/models/input/consent/variable.model';
import { UserOrPatientFullName } from '@app/core/models/input/patient/patient-search.model';
import { Patient } from '@app/core/models/input/patient/patient.model';
import { Clause } from '@app/core/models/input/procedure/clause.model';
import { Procedure } from '@app/core/models/input/procedure/procedure.model';
import { Specialty } from '@app/core/models/input/specialty/specialty.model';
import { UserInput } from '@app/core/models/input/user-input.model';
import { User } from '@app/core/models/input/user.model';
import { ConsentOutputDTO } from '@app/core/models/output/consent/consent-output.model';
import { VarOutputDTO } from '@app/core/models/output/consent/consent-var-output-model';
import { AlertService } from '@app/core/services/alert/alert.service';
import { ClausesService } from '@app/core/services/api/consents/clauses/clauses.service';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { LoginService } from '@app/core/services/login/login.service';
import { PatientService } from '@app/core/services/patient/patient.service';
import { ProcedureService } from '@app/core/services/procedure/procedure.service';
import { RoleService } from '@app/core/services/role/role.service';
import { UserService } from '@app/core/services/user/user.service';
import { UtilsService } from '@app/core/services/utils/utils.service';
import { ElciValidators } from '@app/core/utils/validators';
import {
  fa0,
  fa1,
  fa2,
  fa3,
  fa4,
  faAngleDown,
  faCircleQuestion,
  faHouseMedicalCircleCheck,
  faMaximize,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, Subscription, tap } from 'rxjs';
import { ADMINISTRATORS_ROLES } from '../../../../core/constants/Permissions';
import { SurgeryType } from '../../../../core/models/input/consent/surgery-type.model';
import { VariableService } from '../../../../core/services/utils/variable/variable.service';
import { ConsentsShareService } from '../../services/consents-share.service';
import { GenConsentComponent } from '../gen-consent/gen-consent.component';
import { QuickApiService } from '@app/quick-api/api-handler/services/quick-api/quick-api.service';
import DownloadUtils from '@app/core/utils/download.utils';

@Component({
  selector: 'app-data-consent',
  templateUrl: './data-consent.component.html',
  styleUrls: ['./data-consent.component.scss'],
})
export class DataConsentComponent implements OnInit, OnDestroy {
  // Font awesome
  faMaximize = faMaximize;
  fa0 = fa0;
  fa1 = fa1;
  fa2 = fa2;
  fa3 = fa3;
  fa4 = fa4;
  faAngleDown = faAngleDown;
  faCircleQuestion = faCircleQuestion;
  faUserPlus = faUserPlus;
  faHouseMedicalCircleCheck = faHouseMedicalCircleCheck;
  // Roles used by the html
  ADMINISTRATORS_ROLES = ADMINISTRATORS_ROLES;

  // Form related variables
  dataConsent!: FormGroup;
  dataGenConsentForm!: FormGroup;
  isOnSubmit = false;
  isEditing = false;
  editingConsentUuid?: string;

  selectedSpecialty = '0';
  selectedProcedure = '0';

  isCheckedConsent = true;
  isCheckedInform = true;
  isCheckedCovid = false;
  noAdditionalClause = false;
  searchDoctor = false;
  searchPatient = false;


  @ViewChild(GenConsentComponent) genConsentComponent?: GenConsentComponent;

  @Output() consentGenerated = new EventEmitter<boolean>();
  isButtonChecked = false;

  emitConsentGenerated() {
    this.consentGenerated.emit(this.isButtonChecked);
  }

  // General data variables
  consent: Consent;
  specialties?: Specialty[];
  procedures?: Procedure[];
  filteredProcedures: Procedure[] = [];
  procedure?: Procedure;
  topProcedures?: Procedure[];
  surgeryTypes?: SurgeryType[];
  searchPatients?: UserOrPatientFullName[];
  searchDoctors?: UserOrPatientFullName[];
  selectedPatient?: Patient;
  selectedTemplate?: { templateUuid: string; templateType: string };
  vars?: Variable[];
  additionalClauses?: Clause[];
  selectedAdditionalClause?: Clause;
  user?: User;
  selectedDoctor?: UserInput;
  isAdministrativeGenerating = false;
  patientUuid = '';
  // This property is set in the service when the quick api object is created and its passed to the consent.
  quickApiUuid: string | undefined = undefined;

  showDropdown = false;
  showDropdownDoctor = false;
  showDropdownProcedure = false;
  hasAdditionalClause = false;
  openwindow = false;

  selectedTemplateLanguage= DEFAULT_ELCI_LANGUAGE

  varSubscription?: Subscription;
  genConsentFormSubscription?: Subscription;
  quickApiUuidSubscription?: Subscription;
  private selectedTemplateLanguageSubscription?: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private alertService: AlertService,
    private patientService: PatientService,
    private procedureService: ProcedureService,
    private clauseService: ClausesService,
    private consentService: ConsentService,
    private variableService: VariableService,
    private utilsService: UtilsService,
    private consentsShareService: ConsentsShareService,
    private loginService: LoginService,
    public roleService: RoleService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private quickApiService: QuickApiService,
  ) {
    // Initialize the variables
    this.consent = new Consent();
    this.dataConsent = this.initForm();
    this.formSubscriptions();
    this.disableFormInputs();
  }

  ngOnInit(): void {
    this.initBeforeApiCalls();
    this.getParametersToCreate();
    this.roleService.isQuickapi();
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                                            INIT                                        #
  // #                                                                                        #
  // ##########################################################################################

  /**
   * Initializes user information before making API calls. It retrieves the user information
   * from the login service or creates a new User object if the user is not logged in.
   */
  initBeforeApiCalls() {
    // Get user information from the login service or create a new user object
    this.user = this.loginService.userValue ?? new User();
  }

  /**
   * Initializes data and performs necessary API calls after rendering the component.
   * This method sets up user information, retrieves surgery types, initializes subscriptions,
   * fetches service subscriptions, loads asynchronous translations, checks if the user is an admin,
   * and handles route parameters such as patient UUID and consent UUID for editing.
   */
  initAfterApiCalls() {
    // Initialize variable subscriptions
    this.varSubscriptionSbs();
    this.handleSelectedTemplateLanguageChange();

    // Fetch service subscriptions
    this.getServiceSubscriptions();

    // Load asynchronous translations
    this.getAsyncTranslations();

    // Check if the user is an admin
    this.checkIfUserIsAdmin();

    // Handle route parameters
    this.activatedRoute.params.subscribe(param => {
      // Set the patient UUID if available
      this.patientUuid = param['uuid'];
      if (this.patientUuid) {
        this.getPatientByUuid(param['uuid']);
      }

      // Set the editing consent UUID if available and retrieve the consent data
      this.editingConsentUuid = param['consentUuid'];
      if (this.editingConsentUuid) {
        this.getConsent(this.editingConsentUuid);
      }
    });
  }

  /**
   * Combines multiple API calls into a single observable using forkJoin, retrieves data for specialties,
   * surgery types, and top procedures, and initializes the component with the fetched data.
   */
  private getParametersToCreate(): void {
    this.consentService.getConsentParametersToCreate().subscribe({
      next: parameters => {
        // Specialties
        this.specialties = parameters.specialties;

        // Surgery Types
        this.surgeryTypes = parameters.surgeryTypes;
        // Set a default surgery type value (e.g., SurgeryTypeEnum.MINOR_AMBULATORY)
        this.dataConsent
          .get('type')
          ?.setValue(SurgeryTypeEnum.MINOR_AMBULATORY);

        // Top procedures
        this.topProcedures = parameters.topProcedures;

        // Once the data is available, initialize the component further
        this.initAfterApiCalls();
      },
    });
  }

  checkIfUserIsAdmin() {
    if (this.roleService.hasRole(ADMINISTRATORS_ROLES)) {
      this.dataConsent.get('doctorName')?.addValidators(Validators.required);
      this.isAdministrativeGenerating = true;
    }
  }

  openNewPatient() {
    this.openwindow = true;
    this.openwindow = true;
  }

  getAsyncTranslations(): void {
    this.translate
      .get('PRIVATE.CONSENTS.CI-TEMPLATE.CUSTOMER-RISKS')
      .subscribe((translated: string) => {
        this.dataConsent.get('personalization')?.setValue(translated);
      });
    this.translate
      .get('PRIVATE.CONSENTS.CI-TEMPLATE.OTHER-OBSERVATIONS')
      .subscribe((translated: string) => {
        this.dataConsent.get('observations')?.setValue(translated);
      });
  }

  getServiceSubscriptions(): void {
    this.genConsentFormSubscription =
      this.consentsShareService.genConsentForm$.subscribe(genConsentForm => {
        if (genConsentForm) {
          this.dataGenConsentForm = genConsentForm;
        }
      });
    this.genConsentFormSubscription =
      this.consentsShareService.template$.subscribe(template => {
        if (template) {
          this.selectedTemplate = template;
        }
      });
    this.quickApiUuidSubscription =
      this.quickApiService.quickApiUuid$.subscribe(quickApiUuid => {
        if (quickApiUuid) {
          this.quickApiUuid = quickApiUuid;
          // Once setted in this consent clean the subscription value
          this.quickApiService.setQuickApiUuid(null);
        }
      });
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                      INITIALIZE FORM & FORM CONTROLS SUBSCRIPTIONS                     #
  // #                                                                                        #
  // ##########################################################################################

  private initForm(): FormGroup {
    // Validamos e inicializamos formulario
    return new FormGroup({
      doctorName: new FormControl({ value: '', disabled: false }, [
        Validators.minLength(1),
      ]),
      name: new FormControl({ value: '', disabled: false }, [
        Validators.required,
        Validators.minLength(3),
      ]),
      patientUuid: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      document: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      age: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      expedient: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      type: new FormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      usuals: new FormControl({ value: '0', disabled: false }),
      specialty: new FormControl({ value: '0', disabled: false }, [
        ElciValidators.notEqualToZero,
      ]),
      procedure: new FormControl({ value: '0', disabled: false }, [
        ElciValidators.notEqualToZero,
      ]),
      procedureName: new FormControl({ value: '', disabled: false }),
      additionalClause: new FormControl({ value: '0', disabled: false }),
      consent: new FormControl({ value: true, disabled: false }),
      inform: new FormControl({ value: true, disabled: false }),
      covid: new FormControl({ value: false, disabled: false }),
      personalization: new FormControl({ value: '', disabled: false }),
      observations: new FormControl({ value: '', disabled: false }),
      code1: new FormControl({ value: '', disabled: false }),
      code2: new FormControl({ value: '', disabled: false }),
      subtitle: new FormControl({ value: '', disabled: false }),
      date: new FormControl({ value: '', disabled: false }),
      statusId: new FormControl({ value: undefined, disabled: false }),
    });
  }

  private formSubscriptions() {
    // Subscribe to changes in 'personalization' field and update the customerRisks value in the consent object.
    this.dataConsent
      .get('personalization')
      ?.valueChanges.subscribe(newValue => {
        this.consent.consentAdditionalData.customerRisks = newValue;
        this.updateConsent(this.consent);
      });

    // Subscribe to changes in 'observations' field and update the otherObservations value in the consent object.
    this.dataConsent.get('observations')?.valueChanges.subscribe(newValue => {
      this.consent.consentAdditionalData.otherObservations = newValue;
      this.updateConsent(this.consent);
    });

    // Subscribe to changes in 'code1' field and update the diagnosticCie10 value in the consent object.
    this.dataConsent.get('code1')?.valueChanges.subscribe(newValue => {
      this.consent.consentAdditionalData.diagnosticCie10 = newValue;
      this.updateConsent(this.consent);
    });

    // Subscribe to changes in 'code2' field and update the procedureCie10 value in the consent object.
    this.dataConsent.get('code2')?.valueChanges.subscribe(newValue => {
      this.consent.consentAdditionalData.procedureCie10 = newValue;
      this.updateConsent(this.consent);
    });

    // Subscribe to changes in 'subtitle' field and update the customerTitle value in the consent object.
    this.dataConsent.get('subtitle')?.valueChanges.subscribe(newValue => {
      this.consent.consentAdditionalData.customerTitle = newValue;
      this.updateConsent(this.consent);
    });

    // Subscribe to changes in 'consent' field and update the consent value in the consent object.
    this.dataConsent.get('consent')?.valueChanges.subscribe(newValue => {
      this.consent.consent = newValue;
      this.updateConsent(this.consent);
    });

    // On 'type' field change, find the matching surgery type by ID and update the surgeryType.name in the consent object.
    this.dataConsent.get('type')?.valueChanges.subscribe(newValue => {
      if (this.surgeryTypes) {
        const selectedId = newValue;
        const selectedSurgeryType = this.surgeryTypes.find(
          type => type.id === selectedId
        );

        if (selectedSurgeryType) {
          this.consent.surgeryType.name = selectedSurgeryType.name;
          this.updateConsent(this.consent);
        }
      }
    });

    // On 'name' field change, filter data by name or document.
    this.dataConsent.get('name')?.valueChanges.subscribe(newValue => {
      this.filterByNameOrDocument(newValue);
    });

    // On 'doctorName' field change, filter data by name or document.
    this.dataConsent.get('doctorName')?.valueChanges.subscribe(newValue => {
      this.filterDoctorByNameOrDocument(newValue);
    });

    // On 'additionalClause' change, find the matching clause by code and update the additionalClause in the consent object.
    this.dataConsent
      .get('additionalClause')
      ?.valueChanges.subscribe(newValue => {
        if (newValue) {
          if (newValue == '0') {
            this.consentsShareService.setSelectedAdditionalClause(null);
          }
          this.selectedAdditionalClause = this.additionalClauses?.find(
            clause => clause.code === newValue
          );
          if (this.selectedAdditionalClause) {
            this.consent.consentAdditionalData.additionalClause =
              this.selectedAdditionalClause?.code;
            this.consentsShareService.setSelectedAdditionalClause(
              this.selectedAdditionalClause
            );
            this.updateConsent(this.consent);
          }
        }
      });

    // On 'covid' field change, update the addCovid value in the consent object.
    this.dataConsent.get('covid')?.valueChanges.subscribe(newValue => {
      this.consent.addCovid = newValue;
      this.updateConsent(this.consent);
    });

    // On 'inform' field change, update the beNotified value in the consent object.
    this.dataConsent.get('inform')?.valueChanges.subscribe(newValue => {
      this.consent.beNotified = newValue;
      this.updateConsent(this.consent);
    });

    // On 'usuals' field change, update the selectedTopProcedure and related fields in the consent object.
    this.dataConsent.get('usuals')?.valueChanges.subscribe(newValue => {
      if (newValue !== '0') {
        this.consent.idProcedure = parseInt(newValue);
        this.getProcedure(this.consent.idProcedure).subscribe({
          next: () => {
            this.updateConsent(this.consent);
            this.dataConsent.get('procedure')?.setValue(this.consent.idProcedure);
            this.dataConsent.get('procedureName')?.setValue(this.consent.procedureName);
          },
        });


        this.updateConsent(this.consent);
      }
    });

    // On 'specialty' field change, update the selectedAdditionalClauseId value and reset the 'usuals' field.
    this.dataConsent.get('specialty')?.valueChanges.subscribe(newValue => {
      if (newValue !== '0') {
        this.selectedSpecialty = newValue;
        this.getProcedures(newValue);
        // Restore his validators
        this.dataConsent.get('specialty')?.clearValidators();
        this.dataConsent
          .get('specialty')
          ?.addValidators(ElciValidators.notEqualToZero);
        // Set the selected specialty for other components
        if (this.specialties) {
          const selectedSpecialty = this.specialties.filter(specialty => specialty.id?.toString() === this.selectedSpecialty)[0]
          this.consentsShareService.setSelectedSpecialty(selectedSpecialty);
          this.updateConsent(this.consent);
        }
      }
    });

    // On 'procedure' field change, update the selectedProcedure and related fields in the consent object.
    this.dataConsent.get('procedureName')?.valueChanges.subscribe(newValue => {
      if(this.consent.procedureName != newValue )
        this.showDropdownProcedure = true;
      else
        this.showDropdownProcedure = false;
      if(newValue && this.procedures){
        const filterValue = newValue.toLowerCase();
        this.filteredProcedures = this.procedures.filter(procedure =>
          procedure.name?.toLowerCase().includes(filterValue)
        );
      }
    });
  }

  /**
   * This method is used only if is editing.
   * Fills the data consent form with values from the retrieved consent, enabling editing mode.
   * This method populates the form fields with consent-related data.
   */
  private fillDataConsent() {
    // Enable editing mode
    this.isEditing = true;
    if (this.consent.doctorUuid && this.user?.uuid !== this.consent.doctorUuid) {
      this.findDoctorByUuid(this.consent.doctorUuid);
    }

    // Get patient information if available
    if (this.consent.patient?.uuid) {
      this.getPatientByUuid(this.consent.patient?.uuid);
    }

    // Set the selected procedure
    this.selectedProcedure = this.consent.idProcedure?.toString() ?? '0';

    // Retrieve and populate procedure data based on the consent's procedure ID
    if (this.consent.idProcedure) {
      this.getProcedure(this.consent.idProcedure);

    }

    // Fill form controls with consent additional data

    this.dataConsent.controls['personalization'].setValue(
      this.consent.consentAdditionalData.customerRisks
    );
    this.dataConsent.controls['subtitle'].setValue(
      this.consent.consentAdditionalData.customerTitle
    );
    this.dataConsent.controls['type'].setValue(this.consent.surgeryType?.id);
    this.dataConsent.controls['code1'].setValue(
      this.consent.consentAdditionalData.diagnosticCie10
    );
    this.dataConsent.controls['code2'].setValue(
      this.consent.consentAdditionalData.procedureCie10
    );
    this.dataConsent.controls['observations'].setValue(
      this.consent.consentAdditionalData.otherObservations
    );
    // Set COVID consent and checkbox state
    this.dataConsent.controls['covid'].setValue(this.consent.addCovid);
    this.isCheckedCovid = this.consent.addCovid ?? false;

    // Set inform consent and checkbox state
    this.dataConsent.controls['inform'].setValue(this.consent.beNotified);
    this.isCheckedInform = this.consent.beNotified ?? true;

    // Set general consent and checkbox state
    this.dataConsent.controls['consent'].setValue(this.consent.consent);
    this.isCheckedConsent = this.consent.consent;

    this.consentsShareService.setSelectedOutputType(
      this.consent.outputType?.id ?? OutputTypeEnum.DIGITAL_VID
    );

    this.dataGenConsentForm.controls['isCheckedSendEmailBeforeSign'].setValue(this.consent.sendEmailBeforeSign);   
    this.dataGenConsentForm.controls['deliveryDate'].setValue(this.consent.deliveryDate);   
    
    this.consentsShareService.setGenConsentForm(this.dataGenConsentForm);
  }

  /**
   * Disables specific form inputs within the dataConsent form.
   * This method disables the 'document,' 'age,' and 'expedient' form controls.
   */
  private disableFormInputs() {
    // Disable the 'document' form control
    this.dataConsent.get('document')?.disable();

    // Disable the 'age' form control
    this.dataConsent.get('age')?.disable();

    // Disable the 'expedient' form control
    this.dataConsent.get('expedient')?.disable();

    if (this.roleService.isQuickapi()) {
      this.dataConsent.get('name')?.disable();
    }
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                                       UTILITIES                                        #
  // #                                                                                        #
  // ##########################################################################################

  // función que valida cada dato del formulario
  shouldShowError(inputControlName: string): boolean {
    const control = this.dataConsent.get(inputControlName);
    return !!(this.isOnSubmit && control?.invalid);
  }

  showWarningDoctor() {
    this.searchDoctor = !this.searchDoctor;
  }

  showWarningPatient() {
    this.searchPatient = !this.searchPatient;
  }

  toggleDropdown(forceShow = false, valueIfForce = true) {
    if (forceShow) {
      this.showDropdown = valueIfForce;
    } else this.showDropdown = !this.showDropdown;
    this.searchPatient = false;
  }

  toggleDropdownDoctor(forceShow = false, valueIfForce = true) {
    if (forceShow) {
      this.showDropdownDoctor = valueIfForce;
    } else this.showDropdownDoctor = !this.showDropdownDoctor;
    this.searchDoctor = false;
  }

  toggleDropdownProcedure(){
    this.showDropdownProcedure = !this.showDropdownProcedure;
  }

  filterByNameOrDocument(name: string) {
    if (name.length >= 3) {
      this.getPatientsByNameOrDocument(name);
    }
    this.toggleDropdown(true);
  }

  filterDoctorByNameOrDocument(name: string) {
    if (name.length >= 1) {
      this.getByNameOrDocument(name);
    }
    this.toggleDropdownDoctor(true);
  }

  findPatientByUuid(uuid: string | undefined) {
    this.toggleDropdown();
    if (uuid) {
      this.getPatientByUuid(uuid);
    }
  }

  findProcedureByCode(code: string | undefined) {
    this.toggleDropdown();
    if (code) {
      this.consent.idProcedure = parseInt(code);
      this.getProcedure(this.consent.idProcedure).subscribe({
        next: () => {
          this.updateConsent(this.consent);
          this.dataConsent.get('procedure')?.setValue(this.consent.idProcedure);
          this.dataConsent.get('procedureName')?.setValue(this.consent.procedureName);
        },
      });
    }
  }

    

  findDoctorByUuid(uuid: string | undefined) {
    this.toggleDropdownDoctor();
    if (uuid) {
      this.getDoctorByUuid(uuid);
    }
  }

  handleChildPatientUuid(patientUuid: string) {
    this.getPatientByUuid(patientUuid);
    this.openwindow = false;
  }
  closeWindow() {
    this.openwindow = false;
  }

  changeHasAdditionalClause() {
    this.hasAdditionalClause = SPECIALTIES_ID_WITH_ADDITIONAL_CLAUSE.includes(
      parseInt(this.selectedSpecialty)
    );
    if (!this.hasAdditionalClause) {
      this.consent.consentAdditionalData.additionalClause = undefined;
      this.updateConsent(this.consent);
    }
  }

  cancel() {
    this.noAdditionalClause = false;
  }

  CheckedConsent() {
    this.isCheckedConsent = !this.isCheckedConsent;
  }
  CheckedInform() {
    this.isCheckedInform = !this.isCheckedInform;
  }
  CheckedCovid() {
    this.isCheckedCovid = !this.isCheckedCovid;
  }

  /**
   * Retrieves the additional clause procedure ID based on the given specialty ID.
   * This method uses a switch statement to map specialty IDs to specific additional clause procedure IDs.
   *
   * @param specialtyId The ID of the specialty for which to determine the additional clause procedure ID.
   * @returns The additional clause procedure ID as a string.
   */
  getAdditionalClauseProcedureIdPerSpecialty(specialtyId: string): string {
    switch (specialtyId) {
      case '5':
        return OPHTHALMOLOGY_ADDITIONAL_CLAUSE;
      case '31':
        return ANESTESIA_ADDITIONAL_CLAUSE;
      default:
        return '0'; // Default value when the specialty is not mapped to a specific procedure ID.
    }
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                             SHARED SERVICES & SUBSCRIPTIONS                            #
  // #                                                                                        #
  // ##########################################################################################

  updateConsent(consent: Consent) {
    this.consentService.setConsent(consent);
  }

  updateDoctor(doctor: UserInput) {
    this.consentsShareService.setDoctor(doctor);
  }

  varSubscriptionSbs() {
    this.varSubscription = this.variableService.vars$.subscribe(vars => {
      if (vars) {
        this.vars = vars;
      }
    });
  }

  handleSelectedTemplateLanguageChange(): void {
    this.selectedTemplateLanguageSubscription =
      this.consentsShareService.templateLanguage$.subscribe(
        locale => {
          if (locale && this.selectedTemplateLanguage !== locale && locale != '') {
            this.selectedTemplateLanguage = locale;
            this.getProcedures(this.selectedSpecialty);
          }
        }
      );
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                                      API CALLS                                         #
  // #                                                                                        #
  // ##########################################################################################

  private getPatientsByNameOrDocument(searchTerm: string) {
    this.patientService.getPatientsByNameOrDocument(searchTerm).subscribe({
      next: searchPatients => {
        this.searchPatients = searchPatients;
      },
    });
  }

  private getByNameOrDocument(searchTerm: string) {
    this.userService.getDoctorsByNameOrDocument(searchTerm).subscribe({
      next: searchDoctors => {
        this.searchDoctors = searchDoctors;
      },
    });
  }

  private getDoctorByUuid(uuid: string) {
    this.userService.getUserByUuid(uuid).subscribe({
      next: doctor => {
        this.selectedDoctor = doctor;
        this.dataConsent
          .get('doctorName')
          ?.setValue(
            `${doctor.name ?? ''} ${doctor.firstLastname ?? ''} ${
              doctor.secondLastname ?? ''
            }`
          );
        this.updateDoctor(doctor);
        this.toggleDropdownDoctor(true, false);
        if (doctor?.uuid) {
          this.getTopProcedures(doctor.uuid).subscribe({
            next: topProcedures => {
              this.topProcedures = topProcedures;
            },
          });
        }
        this.getSpecialties().subscribe({
          next: specialties => {
            this.specialties = specialties;
          },
        });
      },
    });
  }

  private getPatientByUuid(uuid: string) {
    this.patientService.getPatientByUuid(uuid).subscribe({
      next: patient => {
        this.consent.patient = patient;

        this.dataConsent
          .get('name')
          ?.setValue(
            `${patient.name ?? ''} ${patient.firstLastName ?? ''} ${
              patient.secondLastName ?? ''
            }`
          );
        this.dataConsent.get('document')?.setValue(patient.documentNumber);
        this.dataConsent
          .get('age')
          ?.setValue(this.utilsService.getAge(patient.birthdate));
        this.dataConsent.get('patientUuid')?.setValue(patient.uuid);
        this.dataConsent.get('expedient')?.setValue(patient.healthHistoryExt);
        this.updateConsent(this.consent);
        this.toggleDropdown(true, false);
      },
    });
  }

  private getClausesByProcedure(procedureId: string) {
    this.clauseService.getClausesByProcedure(procedureId).subscribe({
      next: additionalClauses => {
        additionalClauses = additionalClauses.filter(
          clause => clause.title !== 'Description'
        );
        this.additionalClauses = additionalClauses;
        if (this.consent.consentAdditionalData.additionalClause) {
          this.dataConsent.controls['additionalClause'].setValue(
            this.consent.consentAdditionalData.additionalClause
          );
        }
      },
    });
  }

  /**
   * Retrieves specialties for a user, either based on the consent's doctor UUID or the user's UUID.
   *
   * @returns An observable that emits an array of specialties.
   */
  private getSpecialties(): Observable<Specialty[]> {
    // Check if a user is logged in
    if (this.user?.uuid) {
      // Use the consent's doctor UUID if available, otherwise, use the user's UUID
      const doctorUuid = this.selectedDoctor?.uuid ?? this.user?.uuid;

      // Retrieve user specialties from the userService
      return this.userService.getUserSpecialties(doctorUuid);
    } else {
      // Return an empty observable if no user is logged in
      return new Observable<Specialty[]>();
    }
  }

  /**
   * Retrieves procedures for a specific specialty and updates the component's state.
   *
   * @param idSpecialty The ID of the specialty for which procedures are to be retrieved.
   */
  getProcedures(idSpecialty: string) {
    if (idSpecialty && idSpecialty !== '0') {
      // Trigger a change in the hasAdditionalClause property
      this.changeHasAdditionalClause();

      // Retrieve procedures from the service
      this.procedureService.getProcedures(idSpecialty, this.selectedTemplateLanguage.substring(0,2)).subscribe({ // ES_es -> ES
        next: procedures => {
          // Update the component's procedures list
          this.procedures = procedures;
          this.filteredProcedures = procedures;
          // Check if there's an additional clause and the selectedSpecialty is not '0'
          if (this.hasAdditionalClause && this.selectedSpecialty !== '0') {
            // Retrieve clauses by procedure based on the additional clause procedure ID
            this.getClausesByProcedure(
              this.getAdditionalClauseProcedureIdPerSpecialty(
                this.selectedSpecialty
              )
            );
          }
        },
      });
    }
  }

  /**
   * Retrieves a procedure by its ID and updates the component's state accordingly.
   *
   * @param idProcedure The ID of the procedure to retrieve.
   */
  getProcedure(idProcedure: number): Observable<void> {
    return this.procedureService
      .getProcedureWithImagesByCode(idProcedure.toString(), this.selectedTemplateLanguage.substring(0, 2))
      .pipe(
        tap((procedure: Procedure | undefined) => {
          if (procedure) {
            this.selectedSpecialty = procedure.specialityId?.toString() ?? '0';
            if (procedure.code) {
              this.selectedProcedure = procedure.code;
            }
  
            if (this.dataConsent.get('specialty')?.value !== this.selectedSpecialty) {
              this.dataConsent.controls['specialty'].patchValue(procedure.specialityId?.toString());
            }
            this.dataConsent.get('procedure')?.setValue(this.consent.idProcedure);
            this.dataConsent.get('procedureName')?.setValue(this.consent.procedureName);

            this.consentsShareService.setSelectedProcedure(procedure);
            this.procedure = procedure;
          }
        }),
        map(() => void 0) // Transforma la salida a `void`
      );
  }

  /**
   * Retrieves a consent by its UUID and updates the component's state accordingly.
   *
   * @param consentUuid The UUID of the consent to retrieve.
   */
  getConsent(consentUuid: string) {
    // Retrieve the consent from the service
    this.consentService.getConsent(consentUuid).subscribe({
      next: consent => {
        // Update the component's consent property with the retrieved consent
        this.consent = consent;

        // Fill the data consent with the consent data
        this.fillDataConsent();
      },
    });
  }

  getTopProcedures(userUuid: string): Observable<Procedure[]> {
    return this.procedureService.getTopProcedures(userUuid);
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                                     SUBMIT ZONE                                        #
  // #                                                                                        #
  // ##########################################################################################

  mapDataConsent(): ConsentOutputDTO {
    return {
      
      doctorUuid: this.isAdministrativeGenerating
        ? this.selectedDoctor?.uuid
        : this.user?.uuid,
      administrativeUuid: this.isAdministrativeGenerating
        ? this.user?.uuid
        : undefined,
      idProcedure:
        this.dataConsent.get('procedure')?.value !== '0'
          ? this.dataConsent.get('procedure')?.value
          : this.consent.idProcedure,
      idSpecialty:
        this.dataConsent.get('specialty')?.value !== '0'
          ? this.dataConsent.get('specialty')?.value
          : this.consent.specialty,
      outputTypeId: this.dataGenConsentForm.get('outputTypeId')?.value,
      statusId: this.dataConsent.get('statusId')?.value,
      surgeryTypeId: this.dataConsent.get('type')?.value,
      beNotified: this.dataConsent.get('inform')?.value,
      consent: this.dataConsent.get('consent')?.value,
      addCovid: this.dataConsent.get('covid')?.value,
      templateUuid: this.selectedTemplate?.templateUuid,
      templateType: this.selectedTemplate?.templateType,
      quickApiUuid: this.quickApiUuid ?? undefined,
      deliveryDate: this.dataGenConsentForm.get('deliveryDate')?.value,
      // If the patient does not have two representatives, force the DEFAULT sign type
      digitalCiSignatureSignTypeId:
        this.utilsService.patientHasTwoRepresentativesActive(
          this.consent.patient ?? new Patient()
        )
          ? this.consent.digitalCiSignatureSignTypeId ??
            DigitalCiSignatureSignTypeEnum.DEFAULT
          : DigitalCiSignatureSignTypeEnum.DEFAULT,
      variables: this.constructVariables(this.vars ?? []), // Initialize as an empty array
      device:
        OutputTypeEnum.DIGITAL_VID ===
        this.dataGenConsentForm.get('outputTypeId')?.value
          ? this.dataGenConsentForm.get('device')?.value
          : undefined,
      sendEmail:
        OutputTypeEnum.PAPER !==
        this.dataGenConsentForm.get('outputTypeId')?.value
          ? this.dataGenConsentForm.get('isSendEmailChecked')?.value
          : undefined,
      patient: {
        // Map patient-related values from the form group
        uuid: this.dataConsent.get('patientUuid')?.value,
        documentNumber: this.dataConsent.get('document')?.value,
        email: this.dataGenConsentForm.get('patientEmail')?.value,
        phonePrefix: this.dataGenConsentForm.get('phonePrefix')?.value,
        mobile: this.dataGenConsentForm.get('patientPhone')?.value,
        representativePatients: [
          {
            documentNumber: this.dataGenConsentForm.get('firstRepresentativeDocumentNumber')?.value,
            email: this.dataGenConsentForm.get('firstRepresentativeEmail')?.value,
            phonePrefix: this.dataGenConsentForm.get('phonePrefix1')?.value,
            mobile: this.dataGenConsentForm.get('firstRepresentativePhone')?.value,
          },
          {
            documentNumber: this.dataGenConsentForm.get('secondRepresentativeDocumentNumber')?.value,
            email: this.dataGenConsentForm.get('secondRepresentativeEmail')?.value,
            phonePrefix: this.dataGenConsentForm.get('phonePrefix2')?.value,
            mobile: this.dataGenConsentForm.get('secondRepresentativePhone')?.value,
          }
        ]
      },
      consentAdditionalData: {
        customerTitle: this.consent?.consentAdditionalData.customerTitle,
        customerRisks: this.consent?.consentAdditionalData?.customerRisks,
        otherObservations:
          this.consent?.consentAdditionalData?.otherObservations,
        procedureCie10: this.consent?.consentAdditionalData?.procedureCie10,
        diagnosticCie10: this.consent?.consentAdditionalData?.diagnosticCie10,
        additionalClause:
          this.dataConsent.get('additionalClause')?.value !== '0'
            ? this.dataConsent.get('additionalClause')?.value
            : '',
      },
      sendEmailBeforeSign: this.dataGenConsentForm.get('isCheckedSendEmailBeforeSign')?.value,
    };
  }

  constructVariables(variables: Variable[]): VarOutputDTO[] {
    return variables.map(variable => {
      const { uuid, value } = variable;
      return { uuid, value };
    });
  }

  setConsentOutputId(idOutput: string) {
    this.dataGenConsentForm.get('outputTypeId')?.setValue(idOutput);   
  }

  setConsentStatusId(statusId: number) {
    this.dataConsent.get('statusId')?.setValue(statusId);    
  }

  // Submit
  onSubmit() {
    this.isOnSubmit = true;
    if (this.dataConsent.valid && this.dataGenConsentForm?.valid) {
      this.submitDataConsentForm();
    } else {
      this.scrollToTop();
      this.alertService.error(
        this.translate.instant(
          'PRIVATE.CONSENTS.DATA-CONSENT.FORMS-NOT-VALID-ERROR'
        )
      );
    }
  }

  continueOnSubmit() {
    this.isButtonChecked = true;

    this.emitConsentGenerated();
    if (!this.isEditing) {
      this.submitSave();
    } else {
      this.submitEdit();
    }
  }

  submitDataConsentForm() {
    if (
      this.hasAdditionalClause &&
      this.dataConsent.get('additionalClause')?.value === '0'
    ) {
      this.noAdditionalClause = true;
      this.scrollToTop();
    } else {
      this.continueOnSubmit();
    }
  }
  
  submitSave() {   
    const consentData = this.mapDataConsent()    
    this.consentService.newConsent(consentData).subscribe({
      next: dataDocument => {       
        if(consentData.outputTypeId === OutputTypeEnum.PAPER && dataDocument.fileOutputDTO != undefined){          
            DownloadUtils.downloadFileOutputPaper(dataDocument.fileOutputDTO);               
        }
        
        this.alertService.success(
          this.translate.instant(
            'PRIVATE.CONSENTS.DATA-CONSENT.CREATE-CONSENT-SUCCESS'
          )
        );
        if (this.roleService.isQuickapi()) {
          const patientName = `${this.consent.patient?.name ?? ''} ${
            this.consent.patient?.firstLastName ?? ''
          } ${this.consent.patient?.secondLastName ?? ''}`;
          this.router.navigate(['/portal/quick-api/patients/doc-ok'], {
            queryParams: {
              document: 'Consentimiento Informado',
              patientName: patientName,
            },
          });
        } else {
          this.router.navigate(['../'], { relativeTo: this.route });
        }
      },
      error: () => {
        this.alertService.error(
          this.translate.instant(
            'PRIVATE.CONSENTS.DATA-CONSENT.CREATE-CONSENT-ERROR'
          )
        );
      },
    });
  }

  submitEdit() {
    if (this.editingConsentUuid) {
      this.consentService
        .updateConsent(this.editingConsentUuid, this.mapDataConsent())
        .subscribe({
          next: () => {
            this.alertService.success(
              this.translate.instant(
                'PRIVATE.CONSENTS.DATA-CONSENT.CREATE-CONSENT-SUCCESS'
              )
            );
            if (this.roleService.isQuickapi()) {
              const patientName = `${this.consent.patient?.name ?? ''} ${
                this.consent.patient?.firstLastName ?? ''
              } ${this.consent.patient?.secondLastName ?? ''}`;
              this.router.navigate(['/portal/quick-api/patients/doc-ok'], {
                queryParams: {
                  document: 'Consentimiento Informado',
                  patientName: patientName,
                },
              });
            } else {
              this.router.navigate(['../'], { relativeTo: this.route });
            }
          },
          error: () => {
            this.alertService.error(
              this.translate.instant(
                'PRIVATE.CONSENTS.DATA-CONSENT.UPDATE-CONSENT-ERROR'
              )
            );
          },
        });
    }
  }

  private scrollToTop() {
    (function smoothscroll(): void {
      const currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;

      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })();
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                                       DESTROY                                          #
  // #                                                                                        #
  // ##########################################################################################

  ngOnDestroy(): void {
    if (this.varSubscription) {
      this.varSubscription.unsubscribe();
    }
    if (this.genConsentFormSubscription) {
      this.genConsentFormSubscription.unsubscribe();
    }
    if (this.quickApiUuidSubscription) {
      // Set the uuid to null
      this.quickApiService.setQuickApiUuid(null);
      this.quickApiUuidSubscription.unsubscribe();
    }
    if (this.selectedTemplateLanguageSubscription) {
      this.selectedTemplateLanguageSubscription.unsubscribe();
    }
  }
}
