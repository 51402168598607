<div class="buttons">
    <button class="button tipo-second-color back-white-color" (click)="refreshOnParent()">
        <fa-icon [icon]="faRotate" class="icono" *ngIf="!refreshingConsents"></fa-icon>
        <fa-icon [icon]="faRotate" class="icono" animation="spin-pulse" *ngIf="refreshingConsents"></fa-icon>
    </button>
    <button *appHasRole="GENERATE_CIS" class="button tipo-white-color back-blue-color" [routerLink]="['new']">
            <fa-icon [icon]="faFileCirclePlus" class="icono iconono"></fa-icon>
           {{ "PRIVATE.CONSENTS.BUTTONS-CONSENT.BUTTON-NEW-CONSENT" | translate }}
    </button>

 </div>
