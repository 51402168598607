<div class="wrapper">
    <div class="back">
        <img class="movil" src="../../../assets/img/clientes/clientes-movil.jpg"
        alt="Accede a la aplicación de elConsentimiento.es">
        <img class="desktop" src="../../../assets/img/clientes/clientes.jpg"
        alt="Accede a la aplicación de elConsentimiento.es">

        <div class="message_front">
            <h2 class="tipo-white-color">
                {{'PRIVATE.MESSAGE-DOC-ENDED.TITLE-THE' | translate}} {{document}} {{'PRIVATE.MESSAGE-DOC-ENDED.TITLE-OF' | translate }} {{patientName}} {{'PRIVATE.MESSAGE-DOC-ENDED.TITLE-REST' | translate}} {{actionDoc}} {{'PRIVATE.MESSAGE-DOC-ENDED.TITLE-OK' | translate }}
            </h2>
            <div class="buttons">
                <button class="button tipo-white-color back-blue-color" (click)="close()">
                    <fa-icon [icon]="faCircleXmark" class="icono iconono"></fa-icon>
                    {{'PRIVATE.MESSAGE-DOC-ENDED.BUTTON-CLOSE' | translate }}
                  </button>
            </div>
        </div>
        
    </div>
</div>
