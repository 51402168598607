<div class="wrapper">
    <div class="maxwidth">
  
      <h4 class="title tipo-second-color bold">{{ 'PRIVATE.PATIENT.FILE-PATIENT.TITLE' | translate }}</h4>
      
      <div class="header_patient inlineflex back-white-color" >
        <fa-icon [icon]="faHospitalUser" class="icono tipo-second-color fa-xl"></fa-icon>
        <h4 class="title tipo-second-color">{{patientFullName}} </h4>
      </div>
  
      <div class="tabset">
  
        <!-- Tab 1 -->
        <input type="radio" name="tabset" id="tab1" aria-controls="file" [checked]="selectedTab === PatientParamsRouteEnum.FILE" (change)="changeTab(PatientParamsRouteEnum.FILE)" />
        <label for="tab1" class="bold tipo-main-color">{{ 'PRIVATE.PATIENT.FILE-PATIENT.TITLE-TAB1' | translate }}</label>
        <!-- Tab 2 -->
        <input type="radio" name="tabset" id="tab2" aria-controls="consents" [checked]="selectedTab === PatientParamsRouteEnum.CONSENTS" (change)="changeTab(PatientParamsRouteEnum.CONSENTS)" *appHasRole="VIEW_CIS"/>
        <label for="tab2" class="bold tipo-main-color" *appHasRole="VIEW_CIS">{{ 'PRIVATE.PATIENT.FILE-PATIENT.TITLE-TAB2' | translate }}</label>
        <!-- Tab 3 -->
        <input type="radio" name="tabset" id="tab3" aria-controls="gdpr" [checked]="selectedTab === PatientParamsRouteEnum.GDPR" (change)="changeTab(PatientParamsRouteEnum.GDPR)" *ngIf="isBiometric"/>
        <label for="tab3" class="bold tipo-main-color" *ngIf="isBiometric"> {{ 'PRIVATE.PATIENT.FILE-PATIENT.TITLE-TAB3' | translate }}</label>
        <!-- Tab 4 -->
        <input type="radio" name="tabset" id="tab4" aria-controls="generate-gdpr" [checked]="selectedTab === PatientParamsRouteEnum.NEWRGPD" (change)="changeTab(PatientParamsRouteEnum.NEWRGPD)" *ngIf="isBiometric" />
        <label for="tab4" class="bold tipo-main-color" *ngIf="isBiometric">{{ 'PRIVATE.PATIENT.FILE-PATIENT.TITLE-TAB5' | translate }}</label>
  
        <div class="tab-panels">
          <!-- datos paciente -->
          <section id="file" class="tab-panel">
            <app-data-patient [inEdit]="false" [isDisabled]="true" [isNewGDPR]="false" (patientEvent)="handleChildPatientFullName($event)" ></app-data-patient>
          </section>
          <!-- consentimiento paciente -->
          <section id="consents" class="tab-panel" *appHasRole="VIEW_CIS">
            <app-documents-patient [isOpenFromPatientFile]="true"></app-documents-patient>
          </section>
          <!-- permisos RGPD -->
          <section id="gdpr" class="tab-panel">
            <app-rgpd-leaves [isOpenFromPatientFile]="true" [showRGPDspermission]="showRGPDspermission"></app-rgpd-leaves>
          </section>
          <!-- documentos a medida -->
          <section id="generate-gdpr" class="tab-panel" >
            <app-gen-docs></app-gen-docs>
          </section>
        </div>
      </div>
  
    </div>
    <div class="window nodisplay">
      <app-window-error></app-window-error>
    </div>
  
   
  </div>
  