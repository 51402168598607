import { Component, Input, OnInit } from '@angular/core';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { DeviceService } from '../../../../core/services/device/device.service';
import { Device } from '@app/core/models/input/device.model';
import { LoginService } from '@app/core/services/login/login.service';
import { User } from '../../../../core/models/input/user.model';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { ReSendDocumentDTO } from '@app/core/models/output/consent/re-send-documentDTO.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/core/services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Patient } from '@app/core/models/input/patient/patient.model';
import { RoleService } from '@app/core/services/role/role.service';

@Component({
  selector: 'app-pending-sign-rl2-consent',
  templateUrl: './pending-sign-rl2-consent.component.html',
  styleUrls: ['./pending-sign-rl2-consent.component.scss']
})
export class PendingSignRl2ConsentComponent implements OnInit {
  faPaperPlane = faPaperPlane;
  selectValueDevice?: string = '';
  consentUuid?: string;

  @Input()
  onSign = true;

  @Input() patient?: Patient;

  devices?: Device[];
  isSendChecked = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private translate: TranslateService,
    private loginService: LoginService,
    private deviceService: DeviceService,
    private consentService: ConsentService,
    private roleService: RoleService
    ) {
      this.activatedRoute.params.subscribe(param => {
        this.consentUuid = param['consentUuid'];
      });
    }

  ngOnInit(): void {
    const user = this.loginService.userValue ?? new User();

    this.deviceService
    .getUserDevices()
    .subscribe({
      next: devices => {
        this.deviceService.setDevices(devices)
        this.devices = devices;
        this.selectValueDevice = user.userDevice?.description;
      },
    });
  }


  submitSecondRepresentativeDevice() {
    this.isSendChecked = true;
      if (this.onSign) {
        this.submitOnSign();
      } else {
        this.submitOnRevoke();
      }
  }

  submitOnSign() {
    const reSendDocument = new ReSendDocumentDTO;
    reSendDocument.deviceName = this.selectValueDevice;

    if (this.consentUuid) {
      this.consentService
      .sendConsentToSecondLegalRepresentative(this.consentUuid, reSendDocument)
      .subscribe({
        next: () => {
          this.alertService.success(
            this.translate.instant(
              'PRIVATE.CONSENTS.PENDING-SIGN-RL2-CONSENT.SEND-TO-SIGN-RL2-SUCCESS'
            )
          );
          if (this.roleService.isQuickapi()){
            const patientName =  `${this.patient?.name ?? ''} ${this.patient?.firstLastName ?? ''} ${ this.patient?.secondLastName ?? '' }`
            this.router.navigate(['/portal/quick-api/patients/doc-ok'], {queryParams: { document:this.translate.instant('QUICKAPI.QUERY-PARAMS-DOCUMENT-CI'), patientName: patientName, actionDoc: this.translate.instant('QUICKAPI.QUERY-PARAMS-SEND')  }});
          }else{
            this.router.navigate(['../'], { relativeTo: this.activatedRoute });
          }
        },
        error: () => {
          this.alertService.error(
            this.translate.instant(
              'PRIVATE.CONSENTS.PENDING-SIGN-RL2-CONSENT.SEND-TO-SIGN-RL2-ERROR'
            )
          );
        },
      });
    }
  }

  submitOnRevoke() {
    const reSendDocument = new ReSendDocumentDTO;
    reSendDocument.deviceName = this.selectValueDevice;

    if (this.consentUuid) {
      this.consentService
      .revokeConsentToSecondLegalRepresentative(this.consentUuid, reSendDocument)
      .subscribe({
        next: () => {
          this.alertService.success(
            this.translate.instant(
              'PRIVATE.CONSENTS.PENDING-SIGN-RL2-CONSENT.SEND-TO-REVOKE-RL2-SUCCESS'
            )
          );
          this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        },
        error: () => {
          this.alertService.error(
            this.translate.instant(
              'PRIVATE.CONSENTS.PENDING-SIGN-RL2-CONSENT.SEND-TO-SIGN-RL2-ERROR'
            )
          );
        },
      });
    }
  }
}
