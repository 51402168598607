import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/environment.service';
import { UtilsService } from '../utils/utils.service';
import { Pagination } from '@app/core/models/front/pagination';
import { Filter } from '@app/core/models/front/filters';
import { Sorters } from '@app/core/models/front/sorters';
import { Observable } from 'rxjs';
import { Page } from '@app/core/models/input/page/pages.model';
import { DocumentForms } from '@app/core/models/input/rgpd/document-forms.model';
import { RgpdTemplate } from '@app/core/models/input/template/rgpd-template.model';
import { RgpdOutputDTO } from '@app/core/models/output/rgpd/rgpd.model';
import { DEFAULT_ELCI_LANGUAGE } from '@app/core/constants/Constants';
import { ConsentStatusOutputDTO } from '@app/core/models/input/consent/consent-status-output.model';


@Injectable({
  providedIn: 'root'
})
export class RgpdService {

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private uSrv: UtilsService
  ) { }

  getRgpds(pagination: Pagination, filters: Filter[], sorters: Sorters): Observable<Page> {
    return this.http.get<Page>(
      `${this.env.apiUrl}/rgpds?` +
        this.uSrv.genPaginationInUri(pagination)+
        this.uSrv.getFiltersInUri(filters) +
        this.uSrv.genSortersInUri(sorters)
    );
  }

  getRgpdForm(patientUuid: string): Observable<DocumentForms> {
    return this.http.get<DocumentForms>(
      `${this.env.apiUrl}/rgpds/${patientUuid}/forms`
    );
  }

  getRgpdDefaultTemplate(countryCode = DEFAULT_ELCI_LANGUAGE): Observable<RgpdTemplate> {
    return this.http.get<RgpdTemplate>(`${this.env.apiUrl}/rgpds/template-rgpd/`+countryCode);
  }

  postRgpd(rgpdOutput: RgpdOutputDTO) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })
    return this.http.post<RgpdOutputDTO>(`${this.env.apiUrl}/rgpds`, JSON.stringify(rgpdOutput), {headers: headers})
  }

  getRgpdStatus(superdocsUuid: string): Observable<ConsentStatusOutputDTO> {
    return this.http.get(`${this.env.apiUrl}/rgpds/`+ superdocsUuid + `/status`);
  }


}
