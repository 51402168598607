import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConsentsModule } from '@app/private/consents/consents.module';
import { SharedModule } from '@app/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GenConsentsPerSpecialtyComponent } from './pages/gen-consents-per-specialty/gen-consents-per-specialty.component';
import { ListConsentsAdminComponent } from './pages/list-consents-admin/list-consents-admin.component';
import { ListSpecialtiesComponent } from './pages/list-specialties/list-specialties.component';



@NgModule({
  declarations: [
    ListSpecialtiesComponent,  
    ListConsentsAdminComponent,
    GenConsentsPerSpecialtyComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    ConsentsModule
  ],
})
export class MaintenanceModule { }
