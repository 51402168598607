
    <div class="clientes">
        <img class="movil" src="../../../assets/img/clientes/clientes-movil.jpg"
            alt="Accede a la aplicación de elConsentimiento.es">
        <img class="desktop" src="../../../assets/img/clientes/clientes.jpg"
            alt="Accede a la aplicación de elConsentimiento.es">
          <div class="intro_clientes">
            <h1 class="bienvenida tipo-white-color">{{ "PUBLIC.LOGIN.WELLCOME" | translate }}</h1>
            <h4 class="tipo-white-color light">{{"PUBLIC.LOGIN.ACCESS-API" | translate}}</h4>
        
            <form [formGroup]="login" class="login" (ngSubmit)="loginApp()">

                <input type="text" class="usuario" placeholder="Usuario" formControlName="username">
                <input type="password" class="password" placeholder="Contraseña" formControlName="password">
                <p class="olvido" (click)="openWindow()">{{"PUBLIC.LOGIN.FORGET-PASSWORD" | translate}}</p>

                <input type="submit" name="submit" id="submitButton" class="back-blue-color tipo-white-color"
                    value="Entrar">
                <div class="error">
                    <p class="regular tipo-main-color errorinvalid" *ngIf="noValido">{{"PUBLIC.LOGIN.USER-PASS-NOT-VALID" | translate}}</p>
                    <p class="regular tipo-main-color error403" *ngIf="error">{{"PUBLIC.LOGIN.MESSAGE-ERROR" | translate}}</p>
                </div>
            </form>
            <!-- Acceder mediante credencial -->
            <div class="vidchain" >
                <p class="bold">{{"PUBLIC.LOGIN.MESSAGE-VIDCHAIN" | translate}}</p>           
                
                    <p class="regular">{{"PUBLIC.LOGIN.MORE-VIDCHAIN-INFORMATION" | translate}}</p>
            
                <button class="acceder back-blue-color tipo-white-color" (click)="goUrlCredential()">{{"PUBLIC.LOGIN.VIDCHAIN-ACCESS" | translate}}</button>
            
            </div>
           </div>
            <div class="landscape">
                <p class="bold">{{"PUBLIC.LOGIN.MESSAGE-NO-LANDSCAPE" | translate}}</p>
            </div>
        
        <div class="window" *ngIf="isOpenWindow">
            <div class="window_reset">
                <div class="close">
                    <fa-icon [icon]="faCircleXmark" class="icono fa-2xl tipo-second-color" (click)="closeWindow()"></fa-icon>
                </div>
                <h4 class="recupera tipo-main-color bold center">{{"PUBLIC.LOGIN.PASSWORD-RECOVERY" | translate}}</h4>
                <p class="regular tipo-main-color">{{"PUBLIC.LOGIN.RECOVERY-INFORMATION" | translate}}</p>
                <form [formGroup]="reset" (ngSubmit)="resetPassword()" class="reset">
                    <input type="text" class="usuario" placeholder="Usuario" formControlName="usuarioReset">
                    <input type="email" class="email" placeholder="Email" formControlName="emailReset">
                    <input type="submit" name="submit" id="submit" class="back-blue-color tipo-white-color"
                        value="Restablecer contraseña ">
                </form>
            </div>
        </div>

        <!-- Confirmar ususario profesional la primera vez que accede -->
        <form [formGroup]="activation" (ngSubmit)="confirmActivation()" *ngIf="showConfirmActivation">
            <div class="window">
                <div class="window_auth">
                    <h4 class="tipo-second-color bold center">{{"PUBLIC.LOGIN.TITLE-USER-DOCTOR-ACTIVATION" | translate}}</h4>
                    <p class="regular tipo-main-color">{{"PUBLIC.LOGIN.FIRST-PARAGRAPH-ACTIVATION" | translate}}</p>
                    <p class="regular tipo-main-color">{{"PUBLIC.LOGIN.SECOND-PARAGRAPH-ACTIVATION" | translate}} </p>
                    <p class="regular tipo-main-color">{{"PUBLIC.LOGIN.THIRD-PARAGRAPH-ACTIVATION" | translate}} </p>
                    <p class="regular tipo-main-color">{{"PUBLIC.LOGIN.FOUR-PARAGRAPH-ACTIVATION" | translate}} </p>
                    <p class="regular tipo-main-color">{{"PUBLIC.LOGIN.MORE-INFORMATION" | translate}} <a class="tipo-second-color enlace"  [routerLink]="['../politicaprivacidad']" target="_blank">{{"PUBLIC.LOGIN.PRIVATE-POLICY" | translate}}</a></p>
                    <div class="input input_row">
                        <input type="checkbox" formControlName="doctorActivation" id="doctorActivation"   />
                        <label for="doctorActivation" id="checkActivation"
                        [ngClass]="{ isCheckedActivation: isCheckedActivation}"
                        (click)="checkedActivation()">
                            {{"PUBLIC.LOGIN.CHECK-ACCEPT" | translate}} <a class="tipo-second-color semibold"  [routerLink]="['../politicaprivacidad']" target="_blank" rel="noopener noreferrer">{{"PUBLIC.LOGIN.PRIVATE-POLICY" | translate}}</a>
                        </label>
                    </div>
                    <div class="buttons">
                        <button class="button tipo-white-color back-blue-color" [disabled]="!isCheckedActivation">
                            {{"PUBLIC.LOGIN.CONFIRM" | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
