import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { SuperAdminComponent } from './super-admin.component';
import { SuperAdminRoutingModule } from './super-admin-routing.module';
import { CompanyAdminModule } from './company/company-admin.module';
import { HeaderAdminComponent } from './shared/header-admin/header-admin.component';
import { StatisticsModule } from './statistics/statistics.module';
import { PatientsAdminModule } from './patients/patients.module';
import { UserModule } from './users/user.module';
import { MaintenanceModule } from './maintenance/maintenance.module';

@NgModule({
  declarations: [
    SuperAdminComponent,
    HeaderAdminComponent   
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    SuperAdminRoutingModule,
    CompanyAdminModule,
    StatisticsModule,
    PatientsAdminModule,
    UserModule,
    MaintenanceModule   
  ]
})
export class SuperAdminModule { }
