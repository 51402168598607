import Role from "../enums/role.enum";

// Route
export const NEW_DOCTOR_ROLES = [Role.ADMINISTRATIVE, Role.ADMINISTRATIVE_VIEW_CIS, Role.ADMINISTRATIVE_SUPERVISOR, Role.COMPANY]
export const ADMINISTRATORS_ROLES = [Role.ADMINISTRATIVE, Role.ADMINISTRATIVE_VIEW_CIS, Role.ADMINISTRATIVE_SUPERVISOR]
export const OPEN_CIS = [Role.COMPANY_DOCTOR, Role.ADMINISTRATIVE_VIEW_CIS, Role.ADMINISTRATIVE_SUPERVISOR, Role.PREMIUM_DOCTOR]
export const GENERATE_CIS = [Role.ADMINISTRATIVE_SUPERVISOR, Role.COMPANY_DOCTOR, Role.FREEMIUM, Role.PREMIUM_DOCTOR]
export const USERS_LOGIN = [Role.COMPANY_DOCTOR, Role.ADMINISTRATIVE, Role.ADMINISTRATIVE_VIEW_CIS, Role.ADMINISTRATIVE_SUPERVISOR]
export const USERS_ADMIN_ELCI = [Role.ADMIN, Role.ADMIN_OWN_COUNTRY, Role.MANAGER, Role.MANAGER_OWN_COUNTRY]

// Components
export const DOCTOR_ROLES = [Role.COMPANY_DOCTOR, Role.PREMIUM_DOCTOR]
export const COMPANY_ROLES = [Role.COMPANY]
export const FREEMIUM_COMPANY_ROLES = [Role.COMPANY, Role.FREEMIUM]
export const FREEMIUM_DOCTOR_ROLES = [Role.COMPANY_DOCTOR, Role.PREMIUM_DOCTOR, Role.FREEMIUM]
export const FREEMIUM_ROLES = [Role.FREEMIUM]
export const HEADER_ROLES = [Role.COMPANY, Role.COMPANY_DOCTOR, Role.FREEMIUM, Role.PREMIUM_DOCTOR]
export const ALL_ROLES = [Role.ADMINISTRATIVE, Role.ADMINISTRATIVE_VIEW_CIS, Role.ADMINISTRATIVE_SUPERVISOR, Role.COMPANY, Role.COMPANY_DOCTOR, Role.PREMIUM_DOCTOR, Role.FREEMIUM]
export const VIEW_CIS = [Role.ADMINISTRATIVE_VIEW_CIS, Role.ADMINISTRATIVE_SUPERVISOR, Role.COMPANY, Role.COMPANY_DOCTOR, Role.PREMIUM_DOCTOR, Role.FREEMIUM]
export const NOT_AUTH_GENERATE_CIS = [Role.ADMINISTRATIVE_VIEW_CIS, Role.ADMINISTRATIVE ]
export const SUPERVISOR = [Role.ADMINISTRATIVE_SUPERVISOR]