import { HttpResponse } from '@angular/common/http';
import { FileOutputDTO } from '../models/output/file-output.model';

export default class DownloadUtils {
  static downloadFO(fileOutputDTO: HttpResponse<FileOutputDTO>) {
    if (fileOutputDTO.body?.name && fileOutputDTO.body?.content) {
      const fileName = fileOutputDTO.body?.name.replace(/\.pdf$/, '');
      this.download(fileOutputDTO.body?.content, fileName, fileOutputDTO.body?.mimeType)
    }
  }

  static downloadFileOutputPaper(fileOutputDTO: FileOutputDTO) {
    if (fileOutputDTO.name && fileOutputDTO.content) {
      const fileName = fileOutputDTO.name.replace(/\.pdf$/, '');
      this.download(fileOutputDTO.content, fileName, fileOutputDTO.mimeType)
    }
  }

  static download(data: string, filename: string, mimeType: string | undefined) {
    if (!mimeType) {
      mimeType = 'application/pdf';
    }
    const extension = mimeType.split('/')[1];
    const blobURL = URL.createObjectURL(DownloadUtils.pdfBlobConversion(data, mimeType));
    const fileLink = document.createElement('a');
    fileLink.href = blobURL;
    fileLink.download = `${filename}.${extension}`;
    fileLink.click();
  }

  static pdfBlobConversion(b64Data: string, contentType: string): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
