<div class="maxwidth">
<div class="box_container">
 
    <div class="box box2">       
        
        <div class="buttons button_edit" >
            <button class="button tipo-second-color back-white-color" (click)="changeEdit()">
                <fa-icon [icon]="faPenToSquare" class="icono fa-xl"></fa-icon>
            </button>
        </div>
        <form [formGroup]="dataCompanyAdmin" class="form_datacompany" >          
           
            <div class="input">
                <label for="doctorsAmountId"> {{'SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-PROFESSIONALS' | translate }} </label>
                  <select formControlName="doctorsAmountId" id="doctorsAmountId" 
                  [class.back_blue]="this.inEdit" 
                  [ngClass]="{ wrong: shouldShowError('doctorsAmountId')}"
                  >
                   <option value="{{doctors.id}}"
                      *ngFor="let doctors of doctorsAmounts">
                      {{ doctors.name }}
                    </option>
                  </select>                  
            </div>
            <p class="message_error wrongtext small" *ngIf="this.dataCompanyAdmin.get('doctorsAmountId')?.errors?.['required'] && this.isSubmit">
                {{'SUPER-ADMIN.CLIENT.DATA-HIRING.MESSAGE-ERROR-PROFESSIONALS' | translate }}
            </p>  
            <div class="input">
                <label for="paymentMethodType" class="paymentMethodType"> {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-PAYMENT-FORM' | translate}}</label>                
                <select type="text" formControlName="paymentMethodType" id="paymentMethodType" 
                (change)="checkPaymentMethod()"
                [class.back_blue]="this.inEdit" 
                [ngClass]="{'wrong': shouldShowError('status')}">  
                    <option value="{{method.id}}"
                    *ngFor="let method of paymentMethodTypes">
                    {{ method.name }}
                  </option> 
                </select>
            </div>
            <p
            class="message_error wrongtext small"
            *ngIf="this.dataCompanyAdmin.get('paymentMethodTypeId')?.errors?.['required'] && this.isSubmit">
            {{ 'SUPER-ADMIN.CLIENT.DATA-HIRING.MESSAGE-ERROR-MODE' | translate }}
          </p>
          <div class="input" *ngIf="showBankAccount">
            <label for="bankAccount" class="bankAccount">{{ "SUPER-ADMIN.CLIENT.DATA-HIRING.LABEL-SEPA" | translate }}</label>                
            <input type="text" formControlName="bankAccount" id="bankAccount" [class.back_blue]="this.inEdit">  
        </div>
            
            <div class="input">
                <label for="emailLOPD" class="emailLOPD">{{ "PRIVATE.COMPANY.DATA-COMPANY.EMAILGDPR" | translate }}</label>                
                <input type="text" formControlName="emailLOPD" id="emailLOPD" [class.back_blue]="this.inEdit">  
            </div>
            <div class="input">
                <label for="website" class="website">{{ "SUPER-ADMIN.CLIENT.DATA-COMPANY.LABEL-PRIVACY-URL" | translate }}</label>                
                <input type="text" formControlName="website" id="website" [class.back_blue]="this.inEdit">  
            </div>
            <div class="input" *ngIf="companyData?.companyAdditionalData?.his !== ''">
                <label for="hisName" class="hisName">{{"SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-HIS-NAME" | translate}}</label>                
                <input type="text" formControlName="his" id="hisName" [class.back_blue]="this.inEdit">  
            </div>
            <div class="input">
                <label for="dateOn" class="dateOn">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-DATE-ON' | translate}}</label>                
                <input type="date" formControlName="dateOn" id="dateOn">  
            </div>
            <div class="input">
                <label for="dateOff" class="dateOff">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-DATE-OFF' | translate}}</label>                
                <input type="date" formControlName="dateOff" id="dateOff">  
            </div>
            <div class="inlineflex">
              
                <div  class="checkinput inlineflex">
                    <label for="apiAccess">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-API-ACCESS' | translate}}</label>
                    <input type="checkbox" id="apiAccess" formControlName="apiAccess">
                </div>                            
            </div>
            <div class="inlineflex">
                <div class="checkinput inlineflex">
                    <label for="paper">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-PAPER' | translate}}</label>
                    <input type="checkbox" id="paper" formControlName="paperAccess" [(ngModel)]="paperAccess" (change)="addSubscriptionTypesId(subscriptionTypeEnum.PAPER, paperAccess)">
                </div>
                <div class="checkinput inlineflex">
                    <label for="digitalAccess">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-DIGITAL-ACCESS' | translate}}</label>
                    <input type="checkbox" id="digitalAccess" formControlName="digitalAccess" [(ngModel)]="digitalAccess" (change)="addSubscriptionTypesId(subscriptionTypeEnum.BIOMETRIC, digitalAccess)">
                </div>
                
                <div class="checkinput inlineflex">
                    <label for="remoteSigns">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-REMOTE-SIGNS' | translate}}</label>
                    <input type="checkbox" id="remoteSigns" formControlName="remoteSigns" [(ngModel)]="remoteSigns" (change)="addSubscriptionTypesId(subscriptionTypeEnum.REMOTE, remoteSigns)">
                </div>                
            </div>
            <div class="buttons"  *ngIf="inEdit || !isDisabled">                   
                <button type="submit" class="button tipo-white-color back-blue-color" (click)="onSubmit()">
                    <fa-icon [icon]="faFileExport" class="icono iconono"></fa-icon>
                    {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-SAVE" | translate }}
                </button>
                <button class="button tipo-main-color back-grey-color" (click)="changeEdit()">
                    {{ "PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL" | translate }}
                </button>
            </div>
            <!-- TODO traer el logotipo y poder cambiarlo -->
            <!-- <div class="input">
                <label for="logoMain">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-LOGO' | translate}}</label>
                <input type="file" formControlName="logoMain" id="logoMain" [class.back_blue]="this.inEdition" >
               
            </div>
            <div class="box_img" >
                <img [src]="" alt="{{ 'PRIVATE.PERSONALIZATION.EDIT-LOGO.TITLE-LOGO' | translate }}" class="logo">     
                <fa-icon [icon]="faTrashCan" class="icono tipo-second-color fa-xl"></fa-icon>
                </div> -->               
        </form>
        <div class="inlineflex" *ngIf=" totalCompanies!== 0">
            <div class="data inlineflex">
                <label for="subcompanies">{{'SUPER-ADMIN.LIST-COMPANY.TITLE-SUBCOMPANIES-NUMBER' | translate}}</label>
                <span class="back_blue">{{companyData?.companies}}</span>
            </div>
            <button class="button tipo-white-color back-blue-color" [routerLink]="['../../companies']" >
                <fa-icon [icon]="faEye" class="icono"></fa-icon>
                {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.BUTTON-SEE-COMPANY' | translate}}
            </button>  
        </div>   
        <div class="inlineflex">
            <div class="data inlineflex">
                <label for="user">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-USERS'| translate}}</label>
                <span class="back_blue">{{totalUsers}}</span>
            </div>
            <button class="button tipo-white-color back-blue-color" [routerLink]="['./users']" *ngIf="totalUsers && totalUsers > 0">
                <fa-icon [icon]="faEye" class="icono"></fa-icon>
                {{'SUPER-ADMIN.DATA-COMPANY-ADMIN.BUTTON-SEE-USERS' | translate}}
            </button>  
        </div>
    </div>
    
    <div class="box box2">
        <h4 class="tipo-second-color title">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.TITLE-DATA-VIDSIGNER' | translate}}</h4>
        
            <div class="input">
                <label for="representName" class="representName">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-REPRESENT-NAME' | translate}}</label>              
                <span class="regular tipo-second-color small">{{representativeCompany?.name}}</span>
            </div> 
        
            <div class="input">
                <label for="representDocument" class="representDocument">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-REPRESENT-DOCUMENT' | translate}}</label>
                <span class="regular tipo-second-color small">{{representativeCompany?.dniCif}}</span>
            </div> 
            
            <div class="input">
                <label for="representEmail" class="representEmail">{{ "PRIVATE.COMPANY.DATA-COMPANY.EMAIL" | translate }}</label>
                <span class="regular tipo-second-color small">{{representativeCompany?.email}}</span>
            </div> 
            
            <div class="input">
                <label for="representMobile" class="representMobile">{{ "PRIVATE.COMPANY.DATA-COMPANY.CELLULAR" | translate }}</label>
                <span class="regular tipo-second-color small">{{representativeCompany?.mobile}}</span>
            </div>   
            
             <div class="input">
                <label for="userSubscription" class="userSubscription">{{ "PRIVATE.COMPANY.DATA-COMPANY.USER" | translate }}</label>
                <span class="regular tipo-second-color small">{{companyDataVidSigner?.subscriptionName}}</span>
            </div>
           
            <div class="input">
                <label for="passSubscription" class="passSubscription">{{ "PRIVATE.COMPANY.DATA-COMPANY.PASS" | translate }}</label>                
                <span class="regular tipo-second-color small">{{companyDataVidSigner?.subscriptionPassword}}</span> 
            </div>
           
            <div class="input">
                <label for="guiSubscription" class="guiSubscription">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-GUI-SUBSCRIPTION' | translate}}</label>                
                <span class="regular tipo-second-color small">{{companyDataVidSigner?.subscriptionGuid}}</span> 
            </div>
            <div class="input">
                <label for="guiClient" class="guiClient">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-GUI-CLIENT' | translate}}</label>                
                <span class="regular tipo-second-color small">{{companyDataVidSigner?.customerGuid}}</span>
            </div>
            <div class="input">
                <label for="legalId" class="legalId">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-LEGAL-ID' | translate}}</label>                
                <span class="regular tipo-second-color small">{{companyDataVidSigner?.legalid}}</span>
            </div>           
           
            <div class="input">
                <label for="enrollmentCode" class="enrollmentCode">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-ENROLLMENT-CODE' | translate}}</label>                
                <span class="regular tipo-second-color small">{{companyDataVidSigner?.enrollmentCode}}</span> 
            </div>
            <div class="input">
                <label for="stateCredential" class="stateCredential">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-STATUS' | translate}}</label>                
                <span class="regular tipo-second-color small">{{companyDataVidSigner?.active ? 'Activo' : 'Desactivo'}}</span>  
            </div>
            <div class="input">
                <label for="dateOnCredential" class="dateOnCredential">{{'SUPER-ADMIN.DATA-COMPANY-ADMIN.DATA-DATE-ON' | translate}}</label>                
                <span class="regular tipo-second-color small">{{companyDataVidSigner?.creationDate | date : 'dd/MM/YYYY'}}</span> 
            </div>
            
               
       

        
    </div>

</div>
</div> 
