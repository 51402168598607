<div class="wrapper">
  <div class="list">
    <h4 class="title tipo-second-color bold" id="title_table">
      {{ 'PRIVATE.CONSENTS.LIST-CONSENTS.TITLE' | translate }}
    </h4>
    <table class="table" aria-describedby="title_table">
      <thead class="tipo-second-color bold">
        <th scope="col" class="id">{{ 'SUPER-ADMIN.LIST-COMPANY.ID' | translate }}</th>
        <th scope="col" class="specialty">
          <div class="column">
            <span>{{ 'SUPER-ADMIN.CONSENTS-BY-DOCTORS.DATA-SPECIALTIES' | translate }}</span>
            <select
              name="specialty"
              id="specialty"
              (ngModel)="(selectedSpecialty)"
              (ngModelChange)="addSpecialtyFilter($event)">
              <option value="0">{{ 'PRIVATE.CONSENTS.LIST-CONSENTS.OPTION-1' | translate }}</option>
              <option *ngFor="let specialty of specialties" [value]="specialty.id">
                {{ specialty.description }}
              </option>
            </select>
          </div>
        </th>
        <th scope="col" class="consent">
          <div class="column">
            <span>{{ 'SUPER-ADMIN.LIST-CONSENTS-ADMIN.DATA-CONSENT' | translate }}</span>
            <input
              type="text"
              name="procedureName"
              (debounceInput)="addConsentFilter($event)"
              [appDebounceInput]="300"
              #filterConsent
              id="filterDocument"
              placeholder="{{ 'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}" />
          </div>
        </th>
        <th scope="col" class="cie">
          <span>{{ 'SUPER-ADMIN.LIST-CONSENTS-ADMIN.DATA-CIE' | translate }}</span>
        </th>

        <th scope="col" class="type">
          <span>{{ 'SUPER-ADMIN.LIST-CONSENTS-ADMIN.DATA-TYPE' | translate }}</span>
        </th>

        <th scope="col" class="state">
          <span>{{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-ACTION' | translate }}</span>
        </th>
      </thead>
      <tbody>
        <tr *ngFor="let procedure of procedures">
          <td class="id">{{ procedure.code }}</td>
          <td class="specialty_name">{{ procedure.speciality }}</td>
          <td class="consent_name">{{ procedure.name }}</td>
          <td class="cie">{{ procedure.cie10 }}</td>
          <td class="type">
            <span class="green bold" *ngIf="procedure.freemium === true">
              {{ 'SUPER-ADMIN.LIST-CONSENTS.TITLE-FREEMIUM' | translate }}
            </span>
          </td>
          <td>
            <button class="button tipo-second-color" (click)="onSpecialtyClick(procedure.code)">
              <fa-icon [icon]="faFileCirclePlus" class="icono iconono"></fa-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Pagination -->
    <app-paginator
      [totalElements]="page?.totalElements ?? 0"
      [numbersOfElements]="page?.size ?? 0"
      [totalPages]="page?.totalPages ?? 0"
      [pageNumber]="pagination.page"
      [lastPage]="page?.last ?? false"
      (paginate)="paginar($event)"></app-paginator>
    <!-- End of Pagination -->
  </div>
  <app-modal [showModal]="showModal" (closeModal)="onCloseModal()">
    <app-gen-consents-per-specialty [procedureId]="selectedProcedureId"></app-gen-consents-per-specialty>
  </app-modal>
</div>
