import { DatePipe, formatDate } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { DEFAULT_ELCI_LANGUAGE, MAIN_LOGO } from '@app/core/constants/Constants';
import {
  ANESTESIA_ADDITIONAL_CLAUSE,
  SPECIALTIES_ID_WITH_ADDITIONAL_CLAUSE,
} from '@app/core/constants/additional-clauses.constants';
import OutputTypeEnum from '@app/core/enums/output-type.enum';
import { VariableTypeEnum } from '@app/core/enums/superdocs.enum';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { Variable } from '@app/core/models/input/consent/variable.model';
import { Patient } from '@app/core/models/input/patient/patient.model';
import { RepresentativePatientDTO } from '@app/core/models/input/patient/representative.model';
import { Clause } from '@app/core/models/input/procedure/clause.model';
import { Procedure } from '@app/core/models/input/procedure/procedure.model';
import { CiTemplate } from '@app/core/models/input/template/ci-template.model';
import { TemplateInputDTO } from '@app/core/models/input/template/template.model';
import { UserInput } from '@app/core/models/input/user-input.model';
import { User, UserSignature } from '@app/core/models/input/user.model';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { LoginService } from '@app/core/services/login/login.service';
import { TemplateService } from '@app/core/services/template/template.service';
import { UserService } from '@app/core/services/user/user.service';
import { VariableService } from '@app/core/services/utils/variable/variable.service';
import StringUtils from '@app/core/utils/string.utils';
import { Subscription } from 'rxjs';
import { OPHTHALMOLOGY_ADDITIONAL_CLAUSE } from '../../../../core/constants/additional-clauses.constants';
import { CompanyService } from '../../../../core/services/company/company.service';
import { ConsentsShareService } from '../../services/consents-share.service';
import { UtilsService } from '@app/core/services/utils/utils.service';
import { UTCToLocalPipe } from '@app/core/pipes/utc-to-local/utc-to-local.pipe';
import { DateTime } from 'luxon';
import { Company } from '@app/core/models/input/company/company.model';
import { UserOutput } from '@app/core/models/output/user-output.model';

@Component({
  selector: 'app-ci-template',
  templateUrl: './ci-template.component.html',
  styleUrls: ['./ci-template.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  providers: [UTCToLocalPipe, DatePipe]
})
export class CiTemplateComponent implements OnInit, OnChanges, OnDestroy {
  // Inputs
  @Input() consent?: Consent;
  @Input() user?: User;

  // General variables
  template?: CiTemplate;
  selectedTemplate?: CiTemplate;
  procedure?: Procedure;
  headerConsentVariables?: { [key: string]: string };
  headerUserVariablesVariables?: { [key: string]: string };
  additionalClauses?: Clause[];
  selectedAdditionalClause?: Clause;
  hasAdditionalClause = false;
  doctorSignature?: string;
  companyMainLogo?: string;
  doctor?: UserInput;
  searchingTemplateUuid: string | null = null;
  selectedOutputType = OutputTypeEnum.DIGITAL_VID;
  company?: Company;

  // Subscriptions
  private consentSubscription?: Subscription;
  private outputChangeSubscription?: Subscription;
  private doctorSubscription?: Subscription;
  private deliveryDateSubscription?: Subscription;
  private selectedProcedureSubscription?: Subscription;
  private selectedAdditionalClauseSubscription?: Subscription;
  private selectedTemplateUuidSubscription?: Subscription;

  constructor(
    private variableService: VariableService,
    private loginService: LoginService,
    private consentService: ConsentService,
    private consentsShareService: ConsentsShareService,
    private userService: UserService,
    private companyService: CompanyService,
    private templateService: TemplateService,
    private utilsService: UtilsService,
    private utcToLocalPipe: UTCToLocalPipe,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.consentsShareService.setSelectedProcedure(null);
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                                            INIT                                        #
  // #                                                                                        #
  // ##########################################################################################

  ngOnInit(): void {
    this.handleOnChanges();
    // Save the session user into a class variable.
    if (!this.user) {
      this.user = this.loginService.userValue ?? new User();
    }
    if (this.user?.companyUuid) {
      this.getCompanyByUuid(this.user.companyUuid);
    }
    if (!this.doctorSignature && this.user?.uuid) {
      this.getDoctorSignature(this.user?.uuid);
    }
    if (!this.companyMainLogo) {
      this.getCompanyLogo(this.user.companyUuid ?? '');
    }
    // Get the templates from the API.
    this.getDefaultTemplate();
  }

  /**
   * Constructs the variables object based on available data or placeholders.
   * If the object is null, it waits and doesn't change the anchor.
   * If the object is present, it replaces values with "--".
   */
  constructVariables(): void {
    this.selectTemplate();
    // Header
    this.constructHeaderConsentVariables();
    this.constructCompanyVariables();

    // Body
    this.constructBodyConsentVariables();
    this.constructBodySigners();
    this.constructBodyClauseVariables();
    this.constructBodyAdditionalClause();
    this.shouldShowBodyAdditionalClause();
    this.shouldShowBodyProcedureImage();
    this.shouldShowBodyProcedureOtherInfo();
    this.shouldShowBodyBlocks();
    this.constructBodyUserVariables();
    // If this.procedure is null, clean the clause vars
    this.cleanClauseVariables();
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                             Consent blocks should show                                 #
  // #                                                                                        #
  // ##########################################################################################

  shouldShowBodyProcedureOtherInfo(): void {
    if (this.selectedTemplate) {
      const shouldShowClause130 = this.procedure?.clauses?.filter(clause => clause.position === 130)[0].text != null;
      // Construct the modified replacement string
      const modifiedReplacementClause130 = shouldShowClause130
        ? `id='clause_130'`
        : `id='clause_130' style='display:none;'`;

      // Get the body content with nullish coalescing for the default value
      const bodyContent = this.selectedTemplate?.body ?? '';

      const replacedContent = bodyContent.replace(
        /id='clause_130' style='display:none;'/g,
        modifiedReplacementClause130
      );

      if (this.selectedTemplate) {
        this.selectedTemplate.body = replacedContent;
      }
    }
  }

  shouldShowBodyProcedureImage(): void {
    if (this.selectedTemplate) {
      const shouldShowClause140 = this.procedure?.clauses?.filter(clause => clause.position === 140)[0].text != null;
      // Construct the modified replacement string
      const modifiedReplacementClause140 = shouldShowClause140
        ? `id='clause_140''`
        : `id='clause_140' style='display:none;'`;

      // Get the body content with nullish coalescing for the default value
      const bodyContent = this.selectedTemplate?.body ?? '';

      const replacedContent = bodyContent.replace(
        /id='clause_140' style='display:none;'/g,
        modifiedReplacementClause140
      );

      if (this.selectedTemplate) {
        this.selectedTemplate.body = replacedContent;
      }
    }
  }

  shouldShowBodyBlocks(): void {
    const blockObjects = this.selectedTemplate?.bodyVariables?.filter(
      variable => variable.type === VariableTypeEnum.BLOCK
    );
    blockObjects?.forEach(block => {
      if (!block) return;
      const representatives = this.consent?.patient?.representativePatients;
      const isFirstRepresentativeActive = this.consent?.patient?.representativePatients?.[0]?.active;
      const isSecondRepresentativeActive = this.consent?.patient?.representativePatients?.[1]?.active;

      let showBlock: boolean | undefined;

      switch (block.name) {
        case 'block1':
          showBlock = (representatives && representatives?.length === 0) || !isFirstRepresentativeActive;
          break;
        case 'block2':
          showBlock = representatives && isFirstRepresentativeActive && representatives?.length === 1;
          break;
        case 'block3':
          showBlock = representatives && isSecondRepresentativeActive && representatives?.length === 2;
          break;
        case 'remote_block1':
          showBlock =
            OutputTypeEnum.DIGITAL_REMOTE === this.selectedOutputType &&
            ((representatives && representatives?.length === 0) || !isFirstRepresentativeActive);
          break;
        case 'remote_block2':
          showBlock =
            OutputTypeEnum.DIGITAL_REMOTE === this.selectedOutputType &&
            representatives &&
            isFirstRepresentativeActive &&
            representatives?.length === 1;
          break;
        case 'remote_block3':
          showBlock =
            OutputTypeEnum.DIGITAL_REMOTE === this.selectedOutputType &&
            representatives &&
            isSecondRepresentativeActive &&
            representatives?.length === 2;
          break;
        case 'block4':
          showBlock = this.consent?.addCovid;
          break;
        case 'block5':
          showBlock = !this.consent?.beNotified;
          break;
        case 'block6':
          showBlock = this.consent?.consent;
          break;
        case 'block7':
          showBlock = !this.consent?.consent;
          break;
        default:
          showBlock = false;
          break;
      }
      const modifiedReplacementBlock = showBlock
        ? `id='%${block.name}%' style='`
        : `id='%${block.name}%' style='display:none;`;

      if (this.selectedTemplate?.body) {
        this.selectedTemplate.body = this.selectedTemplate.body.replace(
          new RegExp(`id='%${block.name}%' style='display:none;`, 'g'),
          modifiedReplacementBlock
        );
      }
      if (this.selectedTemplate?.header) {
        this.selectedTemplate.header = this.selectedTemplate.header.replace(
          new RegExp(`id='%${block.name}%' style='display:none;`, 'g'),
          modifiedReplacementBlock
        );
      }

      this.mapBodyBlocks(showBlock, block.name ?? '');
    });
  }

  shouldShowBodyAdditionalClause(): void {
    if (this.selectedTemplate) {
      const shouldShowAdditionalClause = this.hasAdditionalClause;
      // Construct the modified replacement string
      const modifiedReplacementBlock1 = shouldShowAdditionalClause
        ? `id='additional_clause'`
        : `id='additional_clause' style='display:none;'`;

      // Get the body content with nullish coalescing for the default value
      const bodyContent = this.selectedTemplate?.body ?? '';

      const replacedContent = bodyContent.replace(
        /id='additional_clause' style='display:none;'/g,
        modifiedReplacementBlock1
      );

      // Save var if shouldShowAdditionalClause
      if (shouldShowAdditionalClause) {
        const bodyAdditionalClauseVariables = {
          additional_clause: 'true',
        };
        this.mapVariables(bodyAdditionalClauseVariables);
      }

      if (this.selectedTemplate) {
        this.selectedTemplate.body = replacedContent;
      }
    }
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                                Variable constructors                                   #
  // #                                                                                        #
  // ##########################################################################################

  /**
   * Constructs the body content for signers based on the consent's patient and representatives,
   * if present. It determines the presence and count of representatives and constructs the body
   * content accordingly, updating the template body for each signer.
   */
  constructBodySigners(): void {
    // Check if the consent has a patient associated
    if (this.consent?.patient) {
      // Get the representatives from the patient
      const representatives = this.consent?.patient?.representativePatients;
      const firstRepresentative = representatives?.[0];
      const secondRepresentative = representatives?.[1];
      const isFirstRepresentativeActive = representatives?.[0]?.active;
      const isSecondRepresentativeActive = representatives?.[1]?.active;

      // Determine the presence and count of representatives
      const hasRepresentative = representatives && representatives?.length !== 0;

      // Construct and update body content for the first signer or patient
      if (hasRepresentative && firstRepresentative && isFirstRepresentativeActive) {
        const firstSignerData = this.constructBodyFirstSignerRepresentative(firstRepresentative);
        this.mapVariables(firstSignerData);
        this.updateTemplateBody(firstSignerData);
      } else {
        const signerPatientData = this.constructBodySignerPatient(this.consent.patient);
        this.mapVariables(signerPatientData);
        this.updateTemplateBody(signerPatientData);
      }

      // Construct and update body content for the second signer if applicable
      if (hasRepresentative && secondRepresentative && isSecondRepresentativeActive) {
        const secondSignerData = this.constructBodySecondSignerRepresentative(secondRepresentative);
        this.mapVariables(secondSignerData);
        this.updateTemplateBody(secondSignerData);
      }
    } else {
      // Clean the variables in case of patient or consent undefined
      const empty = {
        signer_name_1: '',
        signer_last_name_1: '',
        signer_document_1: '',
        signer_role_1: '',
        addressee_document_type: '',
        signer_document_type_1: '',
        signer_document_type_2: '',
        signer_role_2: '',
      };
      this.mapVariables(empty);
      this.updateTemplateBody(empty);
    }
  }

  /**
   * Constructs the body content for a signer who is the consent's patient.
   *
   * @param patient The patient for whom to construct the body content.
   * @returns A record containing the body content for the patient signer.
   */
  constructBodySignerPatient(patient: Patient): Record<string, string> {
    return {
      signer_name_1: patient.name ?? '',
      signer_last_name_1: StringUtils.getPatientFullLastNames(patient),
      signer_document_1: patient.documentNumber ?? '',
      addressee_document_type: 'NIF',
      signer_role_1: '',
      signer_document_type_1: 'NIF',
      signer_document_type_2: 'NIF',
    };
  }

  /**
   * Constructs the body content for the first signer who is a representative.
   *
   * @param representative The first representative for whom to construct the body content.
   * @returns A record containing the body content for the first representative signer.
   */
  constructBodyFirstSignerRepresentative(representative: RepresentativePatientDTO): Record<string, string> {
    return {
      signer_name_1: representative.name ?? '',
      signer_last_name_1: StringUtils.getRepPatientFullLastNames(representative),
      signer_document_1: representative.documentNumber ?? '',
      signer_role_1: representative.relationship ?? '',
      addressee_document_type: 'NIF',
      signer_document_type_1: 'NIF',
      signer_document_type_2: 'NIF',
    };
  }

  /**
   * Constructs the body content for the second signer who is a representative.
   *
   * @param representative The second representative for whom to construct the body content.
   * @returns A record containing the body content for the second representative signer.
   */
  constructBodySecondSignerRepresentative(representative: RepresentativePatientDTO): Record<string, string> {
    return {
      signer_name_2: representative.name ?? '',
      signer_last_name_2: StringUtils.getRepPatientFullLastNames(representative ?? new RepresentativePatientDTO()),
      signer_document_2: representative.documentNumber ?? '',
      signer_role_2: representative.relationship ?? '',
    };
  }

  /**
   * Constructs the body content for an additional clause in the template by replacing placeholders
   * with values from the selected additional clause. It then updates the template body with the constructed content.
   */
  constructBodyAdditionalClause(): void {
    // Create an object with additional clause-related variables
    const bodyAdditionalClauseVariables = {
      ADDITIONAL_CLAUSE_TITLE: this.selectedAdditionalClause?.title ?? '',
      ADDITIONAL_CLAUSE_TEXT: this.selectedAdditionalClause?.text ?? '',
    };

    // Map the additional clause variables to the template
    this.mapVariables(bodyAdditionalClauseVariables);

    // Update the template body with consent-related variables
    this.updateTemplateBody(bodyAdditionalClauseVariables);
  }

  /**
   * Constructs body clause-related variables and updates the template body if procedure clauses are available.
   */
  constructBodyClauseVariables(): void {
    // Check if 'this.procedure' is defined and it has 'clauses' property
    if (this.procedure?.clauses) {
      const bodyClauseVariables: { [key: string]: string } = {};

      // Iterate over each clause in the procedure's clauses
      for (const clause of this.procedure?.clauses ?? []) {
        // Construct variable name based on clause's position
        const variableName = `clause${clause.position}`;

        // Use clause's text or default value ('')
        const variableValue = clause.text ?? '';

        // Assign variable name and value to the bodyClauseVariables object
        bodyClauseVariables[variableName] = variableValue;
      }

      // Update the template body with the constructed clause variables
      this.updateTemplateBody(bodyClauseVariables);
    }
  }

  constructBodyConsentVariables(): void {
    // Define body variables related to consent data
    const bodyConsentVariables = {
      observations: this.consent?.consentAdditionalData?.otherObservations ?? '',
      risks: this.consent?.consentAdditionalData?.customerRisks ?? '',
      title: `
      ${this.consent?.procedureName ?? ''}
      ${this.consent?.consentAdditionalData?.customerTitle ?? ''}
      `,
      addressee_name: this.consent?.patient?.name ?? '',
      addressee_last_name: `${this.consent?.patient?.firstLastName ?? ''} ${
        this.consent?.patient?.secondLastName ?? ''
      }`,
      addressee_document: this.consent?.patient?.documentNumber ?? '',
      specialty: this.consentsShareService.getSelectedSpecialty()?.description ?? '',
    };
    this.mapVariables(bodyConsentVariables);
    // Update the template header with consent-related variables
    this.updateTemplateBody(bodyConsentVariables);
  }

  /**
   * Constructs consent-related variables and updates the template header if consent are available.
   */

  constructHeaderConsentVariables(): void {    
    // Define header variables related to consent data
    const headerConsentVariables = {
      date: formatDate(this.consent?.deliveryDate ?? StringUtils.formatDate(new Date()), 'dd/MM/YYYY', this.locale),
      hour:  this.utcToLocalPipe.transform(StringUtils.formatDateMinutes(new Date()), DateTime.TIME_24_SIMPLE) ?? '',
      patient_name: `${this.consent?.patient?.name ?? ''} ${this.consent?.patient?.firstLastName ?? ''} ${
        this.consent?.patient?.secondLastName ?? ''
      }`,
      health_history_ext: this.consent?.patient?.healthHistoryExt ?? '',
      patient_document: this.consent?.patient?.documentNumber ?? '',
      patient_age: this.utilsService.getAge(this.consent?.patient?.birthdate).toString(),
      expedient_number: this.consent?.patient?.healthHistoryExt ?? '',
      diagnosis: this.consent?.consentAdditionalData?.diagnosticCie10 ?? '',
      treatment: this.consent?.consentAdditionalData?.procedureCie10 ?? '',
      surgery_type: this.consent?.surgeryType?.name ?? '',
      title: `
      ${this.consent?.procedureName ?? ''}
      ${this.consent?.consentAdditionalData?.customerTitle ?? ''}
      `,

    };

    // Update the template header with consent-related variables
    this.updateTemplateHeader(headerConsentVariables);
    this.updateTemplateBody(headerConsentVariables);
  }

  /**
   * Constructs user-related variables and updates the template header if user data are available.
   */
  constructCompanyVariables(): void {
    // Define header variables related to consent user
    const companyVariables = {
      company_logo: this.companyMainLogo ?? '',
      company_name: this.user?.company ?? '',
      business_name: this.user?.company ?? '',
      business_email_rgpd: this.company?.emailLOPD ?? '',
      direction: StringUtils.getUserFullAddress(
        this.company?.user ?? new UserOutput()
      ),
    };

    // Update the template header with user-related variables
    this.updateTemplateHeader(companyVariables);
    this.mapVariables(companyVariables);
    this.updateTemplateBody(companyVariables);
  }

    /**
   * Constructs user-related variables and updates the template header if user data are available.
   */
    constructBodyUserVariables(): void {
      let variables: Record<string, string> = {};

      if (this.consent?.doctor) { // If the doctor is defined in the consent its called from detail consent, if not its a new consent
        const variablesDoctorInConsent = {
          professional: `${this.consent.doctor?.name ?? ''} ${this.consent.doctor?.firstLastname ?? ''} ${this.consent.doctor?.secondLastname ?? ''}`,
          number_col: this.consent.doctor?.username ?? '',
          service: this.doctor?.userAdditionalData?.service ?? '',
        };
        variables = {
          ...variablesDoctorInConsent
        }
      } else if (this.doctor != null) { // If the doctor is not null its selected from the doctors select in the admin generation consent page
        const variablesDoctorSelectedBySupervisor = {
          professional: `${this.doctor?.name ?? ''} ${this.doctor?.firstLastname ?? ''} ${this.doctor?.secondLastname ?? ''}`,
          number_col: this.doctor?.username ?? '',
          service: this.doctor?.userAdditionalData?.service ?? '',
        };
        variables = {
          ...variablesDoctorSelectedBySupervisor
        }
      } else if (this.user != null) { // The normal case, the doctor its the logged user
        // Define header variables related to consent user
        const variablesDoctorIsTheUser = {
          professional: `${this.user?.name ?? ''} ${this.user?.lastname ?? ''}`,
          number_col: this.user?.userName ?? '',
          service: this.user?.service ?? '',
        };
        variables = {
          ...variablesDoctorIsTheUser
        }
      }

      const commonVariables = {
        doctor_signature: this.doctorSignature ?? '',
        profesional_signature: this.doctorSignature ?? '',
        company_logo: this.companyMainLogo ?? '',
      }

      variables =  {
        ... variables,
        ...commonVariables
      }

      this.updateTemplateHeader(variables);
      this.updateTemplateBody(variables);
      this.updateTemplateFooter(variables);

    }

  // ##########################################################################################
  // #                                                                                        #
  // #                                       UTILITIES                                        #
  // #                                                                                        #
  // ##########################################################################################

  /**
   * Selects a template from the available templates list, and sets it as the selected template.
   * This method resets the selected template with variables and selects the first template if available.
   * Additionally, it updates the template information in the shared service.
   */
  selectTemplate(): void {
    // Check if there are templates available
    if (this.template) {
      // Clone the first template using deep copy to reset variables
      this.selectedTemplate = JSON.parse(JSON.stringify(this.template));

      // Update the template information in the shared service
      this.consentsShareService.setTemplate({
        templateUuid: this.selectedTemplate?.uuid ?? '',
        templateType: this.selectedTemplate?.type ?? '',
      });
    }
  }

  /**
   * Cleans clause-related variables by removing placeholders (%clauseXX%) from the selected template's body content.
   * This method checks if a procedure is not selected and replaces all %clauseXX% placeholders with an empty string.
   */
  cleanClauseVariables(): void {
    // Check if a procedure is not selected
    if (!this.procedure) {
      // Construct the regular expression to match %clauseXX% placeholders
      const clausePlaceholderRegex = /%clause\d+%/g;

      // Get the body content with nullish coalescing for the default value
      const bodyContent = this.selectedTemplate?.body ?? '';

      // Replace all %clauseXX% placeholders with an empty string in the selected template's body
      if (this.selectedTemplate) {
        this.selectedTemplate.body = bodyContent.replace(clausePlaceholderRegex, '') ?? '';
      }
    }
  }

  private mapBodyBlocks(shouldShow: boolean | undefined, blockName: string): void {
    if (shouldShow) {
      const bodyAdditionalClauseVariables = {
        [blockName]: 'true',
      };
      this.mapVariables(bodyAdditionalClauseVariables);
    }
  }

  mapVariables(bodyConsentVariables: Record<string, string>): void {
    // Iterate through each body variable in the this.selectedTemplate?.bodyVariables array
    for (const bodyVariable of this.selectedTemplate?.bodyVariables ?? [new Variable()]) {
      // Get the name property of the current body variable
      const matchKey = bodyVariable.name ?? '';

      // Check if the matchKey exists as a key in bodyConsentVariables
      if (matchKey in bodyConsentVariables) {
        // If a match is found, assign the value from bodyConsentVariables
        // to the value property of the current body variable
        bodyVariable.value = bodyConsentVariables[matchKey];
      }
    }
    this.variableService.setVars(this.selectedTemplate?.bodyVariables ?? [new Variable()]);
  }

  changeHasAdditionalClause(specialtyId: number) {
    this.hasAdditionalClause = SPECIALTIES_ID_WITH_ADDITIONAL_CLAUSE.includes(specialtyId);
  }

  getAdditionalClausePerSpecialty(specialtyId: string): string {
    switch (specialtyId) {
      case '5':
        return OPHTHALMOLOGY_ADDITIONAL_CLAUSE;
      case '31':
        return ANESTESIA_ADDITIONAL_CLAUSE;
      default:
        return '0';
    }
  }

  /**
   * Updates the template header by replacing variables with their corresponding values.
   * @param variables - The variables to replace in the template header.
   */
  updateTemplateHeader(variables: { [key: string]: string }): void {
    if (this.selectedTemplate) {
      this.selectedTemplate.header = this.variableService.replaceVariables(
        this.selectedTemplate?.header ?? '',
        variables
      );
    }
  }

  /**
   * Updates the template body by replacing variables with their corresponding values.
   * @param variables - The variables to replace in the template body.
   */
  updateTemplateBody(variables: { [key: string]: string }): void {
    if (this.selectedTemplate) {
      this.selectedTemplate.body = this.variableService.replaceVariables(this.selectedTemplate?.body ?? '', variables);
    }
  }

  /**
   * Updates the template header by replacing variables with their corresponding values.
   * @param variables - The variables to replace in the template header.
   */
  updateTemplateFooter(variables: { [key: string]: string }): void {
    if (this.selectedTemplate) {
      this.selectedTemplate.footer = this.variableService.replaceVariables(
        this.selectedTemplate?.footer ?? '',
        variables
      );
    }
  }

  private constructBase64Image(format: string, content: string): string {
    return `data:image/${format};base64,${content}`;
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                             SHARED SERVICES & SUBSCRIPTIONS                            #
  // #                                                                                        #
  // ##########################################################################################

  handleOnChanges(): void {
    this.handleConsentChange();
    this.handleDoctorChange();
    this.handleDeliveryDateChange();
    this.handleSelectedProcedureChange();
    this.handleSelectedAdditionalClauseChange();
    this.handleSelectedTemplateChange();
    this.handleOutputTypeChange();
  }

  // This this the entry point when we are using the @Input
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['consent']) {
      // If the consents changes, construct variables, the construct methods checks if the consent is null.
      // The getTemplates is for reset the variables, for example if we change the clauses, reset the %clause60% anchors
      // when the getTemplates endpoint responds the method construct the vars
      // If we have the consents, call the api to get the procedure.
      this.constructVariables();
    }
  }

  // This are the entry point when we are using the consentSubscription
  handleConsentChange(): void {
    this.consentSubscription = this.consentService.consent$.subscribe(consent => {
      if (consent) {
        this.consent = consent;
        if (this.consent.templateUuid && this.consent.templateUuid !== '') {
          this.getTemplate(this.consent?.templateUuid ?? '');
        } else {
          // This method construct the clause variables
          this.constructVariables();
        }
      }
    });
  }
  // This are the entry point when we are using the consentSubscription
  handleDoctorChange(): void {
    this.doctorSubscription = this.consentsShareService.doctor$.subscribe(doctor => {
      if (doctor) {
        this.doctor = doctor;
        this.getDoctorSignature(doctor.uuid ?? '');
      }
    });
  }

  handleDeliveryDateChange(): void {
    this.deliveryDateSubscription = this.consentsShareService.deliveryDate$.subscribe(deliveryDate => {
      if (deliveryDate && this.consent) {
        this.consent.deliveryDate = deliveryDate;
        this.constructVariables()
      }
    });
  }

  handleSelectedProcedureChange(): void {
    this.selectedProcedureSubscription = this.consentsShareService.selectedProcedure$.subscribe(procedure => {
      if (procedure) {
        this.procedure = procedure;
        if (this.consent) {
          this.consent.procedureName = procedure.name;
        }
        this.changeHasAdditionalClause(procedure.specialityId ?? 0);
        this.constructVariables();
      }
    });
  }

  handleSelectedAdditionalClauseChange(): void {
    this.selectedAdditionalClauseSubscription = this.consentsShareService.selectedAdditionalClause$.subscribe(
      additionalClause => {
        this.selectedAdditionalClause = additionalClause ?? undefined;
        this.constructVariables();
      }
    );
  }

  handleSelectedTemplateChange(): void {
    this.selectedTemplateUuidSubscription = this.templateService.selectedTemplateUuidSubject$.subscribe(
      templateUuid => {
        if (templateUuid && this.searchingTemplateUuid !== templateUuid) {
          this.searchingTemplateUuid = templateUuid;
          this.getTemplate(templateUuid);
        }
      }
    );
  }

  private handleOutputTypeChange(): void {
    this.outputChangeSubscription = this.consentsShareService.selectedOutputType$.subscribe(outputType => {
      if (outputType) {
        this.selectedOutputType = outputType;
        this.constructVariables();
      }
    });
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                                      API CALLS                                         #
  // #                                                                                        #
  // ##########################################################################################

  private getDoctorSignature(uuid: string): void {
    this.userService.getUserSignatureApi(uuid).subscribe({
      next: (data: UserSignature) => {
        if (data) {
          this.userService.deleteUserSignatureStorage();
          data.userUuid = uuid;
          this.userService.setUserSignature(data);
          this.doctorSignature = this.constructBase64Image(data.format ?? '', data.content ?? '');
        }
        this.constructVariables();
      },
    });
  }

  public refreshCompanyLogo(companyUuid: string | undefined) {
    if (companyUuid ) {
      this.companyMainLogo = undefined;
      this.getCompanyLogo(companyUuid);
    }
  }

  private getCompanyLogo(uuid: string): void {
    this.companyService.getCompanyLogoApi(uuid, MAIN_LOGO).subscribe({
      next: (data: UserSignature) => {
        if (data) {
          this.companyMainLogo = this.constructBase64Image(data.format ?? '', data.content ?? '');
        }
      },
    });
  }

  getDefaultTemplate(): void {
    if (!this.isSearchingTemplate) {
      this.consentService.getDefaultConsentTemplates(this.user?.language?.locale ?? DEFAULT_ELCI_LANGUAGE).subscribe({
        next: template => {
          // Save template in component
          this.template = template;
          // Save original template in service
          this.searchingTemplateUuid = template.uuid ?? null;
          this.templateService.setTemplate(JSON.parse(JSON.stringify(template)));
          this.constructVariables();
        },
      });
      // This prevents more charges from being made than necessary. Only the first subscribe will do when !this.templates
      this.template = new TemplateInputDTO();
    }
  }

  isSearchingTemplate = false;

  getTemplate(templateUuid: string): void {    
    if (templateUuid !== this.selectedTemplate?.uuid && !this.isSearchingTemplate) {
      this.isSearchingTemplate = true;
      this.templateService.getCiTemplateByUuid(templateUuid).subscribe({
        next: template => {
          if (template) {
            this.template = template;
            this.templateService.setSelectedTemplateUuid(template.uuid ?? null);
            this.searchingTemplateUuid = template.uuid ?? null;
            this.templateService.setTemplate(JSON.parse(JSON.stringify(template)));
            this.constructVariables();
            if (template.language?.locale) {
              this.consentsShareService.setTemplateLanguage(template.language?.locale);
            }
          }
          this.isSearchingTemplate = false;
        },
        error: () => {
          this.isSearchingTemplate = false;
        }
      });
    }
  }

  getCompanyByUuid(companyUuid: string): void {
    this.companyService.getCompany(companyUuid).subscribe({
      next: company => {
        this.company = company;
        this.constructVariables();
      }
    });
  }

  // ##########################################################################################
  // #                                                                                        #
  // #                                       DESTROY                                          #
  // #                                                                                        #
  // ##########################################################################################

  ngOnDestroy(): void {
    // Set Additional Clause to null
    this.consentsShareService.setSelectedAdditionalClause(null);

    // Unsubscribe
    if (this.consentSubscription) {
      this.consentSubscription.unsubscribe();
    }
    if (this.outputChangeSubscription) {
      this.outputChangeSubscription.unsubscribe();
    }
    if (this.doctorSubscription) {
      this.doctorSubscription.unsubscribe();
    }
    if (this.selectedProcedureSubscription) {
      this.selectedProcedureSubscription.unsubscribe();
    }
    if (this.selectedAdditionalClauseSubscription) {
      this.selectedAdditionalClauseSubscription.unsubscribe();
    }
    if (this.selectedTemplateUuidSubscription) {
      this.selectedTemplateUuidSubscription.unsubscribe();
    }
  }
}
