enum RouteEnum {
    PORTAL = "portal",
    PATIENTS = "patients",
    CONSENTS = "consents",
  }
export default RouteEnum;


export enum PatientParamsRouteEnum {
    FILE = "file",
    DIGITAL_DOCUMENTS = "dds",
    GDPR = "gdpr",
    CONSENTS = "consents",
    NEWRGPD = "generate-gdpr"
  }


