import { Component, OnInit } from '@angular/core';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { FilterUtils } from '@app/core/utils/filter.utils';
import { faSquarePlus, faRightLong, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Sorters } from '@app/core/models/front/sorters';
import { DESC } from '@app/core/constants/Constants';
import { Page } from '@app/core/models/input/page/pages.model';
import { PatientService } from '@app/core/services/patient/patient.service';
import { Patient } from '@app/core/models/input/patient/patient.model';
import { SorterUtils } from '@app/core/utils/sorter.utils';

@Component({
  selector: 'app-list-patients-admin',
  templateUrl: './list-patients-admin.component.html',
  styleUrls: ['./list-patients-admin.component.scss'],
})
export class ListPatientsAdminComponent implements OnInit{
  faSquarePlus = faSquarePlus;
  faRightLong = faRightLong;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  pagination = new Pagination();
  filters = new Array<Filter>();
  sorters = new Sorters('creationDate', DESC);
  patients?: Patient[];
  page?: Page; 
  isCheckedRls = false;
  searchNameFor= 'fullName';
  searchDocumentFor = 'documentNumber';

  // Utils
  filterUtils: FilterUtils;
  sorterUtils: SorterUtils;

  nameFilter = new Filter();
  nameFilterStr?: string;

  constructor(private patientService: PatientService) {
    this.filterUtils = new FilterUtils(this.filters);
    this.sorterUtils = new SorterUtils(this.sorters);
  }

  ngOnInit(): void {   
    this.getPatients();
  }

  paginar(amount: number) {
    this.pagination.page += amount;
    this.getPatients();
  }

  addFilter(name: string, filter: string) {
    // If we filter the requested page will be 0
    this.pagination.page = 0;
    this.filterUtils.addFilter(name, filter);
    this.getPatients();
  }

  getPatients() {
    this.patientService.getPatients(this.pagination, this.filters, this.sorters).subscribe({
      next: data => {
        this.page = data;
        this.patients = data.content;
      },
    });
  }

  sortPatients(property: string, direction: string) {    
    this.sorterUtils.sortData(property, direction);  
    this.getPatients();   
}

checkedRls(){
  this.isCheckedRls = !this.isCheckedRls;
  if(this.isCheckedRls) {
    this.filterUtils.removeFilter('fullName')
    this.filterUtils.removeFilter('documentNumber')
    this.searchNameFor = 'fullNameWithReps';
    this.searchDocumentFor = 'documentNumberWithReps';
 
  }
  else{
    this.filterUtils.removeFilter('fullNameWithReps')
    this.filterUtils.removeFilter('documentNumberWithReps')
    this.searchNameFor = 'fullName';
    this.searchDocumentFor = 'documentNumber';
  }
}
}
