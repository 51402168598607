import { Component, OnInit, Input } from '@angular/core';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { DeviceService } from '../../../../core/services/device/device.service';
import { Device } from '@app/core/models/input/device.model';
import { ConsentService } from '@app/core/services/consent/consent.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReSendDocumentDTO } from '@app/core/models/output/consent/re-send-documentDTO.model';
import { User } from '@app/core/models/input/user.model';
import { LoginService } from '@app/core/services/login/login.service';
import { AlertService } from '@app/core/services/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '@app/core/services/role/role.service';
import { Consent } from '@app/core/models/input/consent/consent.model';

@Component({
  selector: 'app-expired-consent',
  templateUrl: './expired-consent.component.html',
  styleUrls: ['./expired-consent.component.scss'],
})
export class ExpiredConsentComponent implements OnInit {
  faPaperPlane = faPaperPlane;

  selectedDevice: string | undefined;
  devices?: Device[];
  consentUuid?: string;
  isButtonChecked = false;
  @Input() consent?: Consent;


  constructor(
    private router: Router,
    private deviceService: DeviceService,
    private consentService: ConsentService,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private alertService: AlertService,
    private translate: TranslateService,
    private roleService: RoleService,
  ) {
    this.activatedRoute.params.subscribe(param => {
      this.consentUuid = param['consentUuid'];
    });
  }

  ngOnInit(): void {
    const user = this.loginService.userValue ?? new User();
    this.deviceService.getUserDevices().subscribe({
      next: devices => {
        this.deviceService.setDevices(devices)
        this.devices = devices;
        this.selectedDevice = user.userDevice?.description;

      },
    });
  }

  resendConsent(consentUuid: string) {
    const dataResend: ReSendDocumentDTO = {
      deviceName: this.selectedDevice,
    };
    dataResend.deviceName = this.selectedDevice;
    if (this.devices !== undefined) {
      this.isButtonChecked = true;
      this.consentService
        .resendConsentExpired(consentUuid, dataResend)
        .subscribe({
          next: () => {
            this.alertService.success(
              this.translate.instant(
                'PRIVATE.CONSENTS.EXPIRED-CONSENT.MESSAGE-RESEND-CONSENT-OK'
              )
            );
            if (this.roleService.isQuickapi()){          
              const patientName =  `${this.consent?.patient?.name ?? ''} ${this.consent?.patient?.firstLastName ?? ''} ${ this.consent?.patient?.secondLastName ?? '' }`
              this.router.navigate(['/portal/quick-api/patients/doc-ok'], {queryParams: { document:this.translate.instant('QUICKAPI.QUERY-PARAMS-DOCUMENT-CI'), patientName: patientName, actionDoc: this.translate.instant('QUICKAPI.QUERY-PARMAS-GENERATE')  }});
            }else{           
              this.router.navigate(['../'], {relativeTo: this.activatedRoute});
            }

          },
        });
    }
    else{
      this.alertService.error(
        this.translate.instant(
          'PRIVATE.CONSENTS.EXPIRED-CONSENT.MESSAGE-RESEND-CONSENT-ERROR'
        )
      );
    }
  }
}
