import StringUtils from "@app/core/utils/string.utils";
import { OutputType } from "../common/output-type.model";
import { Status } from "../common/status.model";
import { Patient } from "../patient/patient.model";
import { ConsentAdditionalData } from "./consent-additional-data.model";
import { DigitalCiSignature } from "./digital-ci-signature.model";
import { RemoteCiSignature } from "./remote-ci-signature.model";
import { SurgeryType } from "./surgery-type.model";
import { UserInput } from "../user-input.model";

export class Consent {
    id?: number;
    externalId?: string;
    uuid?: string;
    templateUuid?: string;
    patient?: Patient;
    companyName?: string;
    companyUuid?: string;
    specialty?: string;
    idProcedure?: number;
    procedureName?: string;
    status?: Status;
    outputType?: OutputType
    creationDate?: string;
    signatureDate?: string;
    consentAdditionalData = new ConsentAdditionalData();
    surgeryType = new SurgeryType();
    digitalCiSignatureSignTypeId?: number;
    beNotified?: boolean = true;
    sendEmailBeforeSign?: boolean = false;
    consent = true;
    addCovid?: boolean;
    digitalCiSignatures?: DigitalCiSignature[];
    remoteCiSignatures?: RemoteCiSignature[];
    doctorUuid?: string;
    doctor?: UserInput;
    patientId?: string;
    deliveryDate?: string;

    constructor() {
        // Initialize creationDate with the current date in "yyyy-MM-dd" format
        const currentDate = new Date();
        this.creationDate = StringUtils.formatDate(currentDate);
    }
}