<form [formGroup]="dataConsent" class="dataconsent">
  <h5 class="tipo-second-color" *appHasRole="ADMINISTRATORS_ROLES">
    <fa-icon
      [icon]="fa0"
      class="icono fa-1xl back-blue-color tipo-white-color number"></fa-icon>
    {{ 'PRIVATE.CONSENTS.DATA-CONSENT.SELECT-DOCTOR' | translate }}

  </h5>

      <div class="input_row" *appHasRole="ADMINISTRATORS_ROLES">
        <div class="input back-light-blue-color inlineflex input2" dir="ltr" >
          <input
            type="text"
            formControlName="doctorName"
            placeholder="{{
              'PRIVATE.CONSENTS.DATA-CONSENT.SELECT-DOCTOR' | translate
            }}"
            [ngClass]="{ wrong: shouldShowError('doctorName') }" />
          <fa-icon
            [icon]="faAngleDown"
            class="icono tipo-second-color arrow"
            (click)="showWarningDoctor()"></fa-icon>
          <ul
            class="patient_select"
            *ngIf="showDropdownDoctor"
            class="dropdown-list">
            <li
              *ngFor="let doctor of searchDoctors; let i = index"
              [value]="doctor.uuid"
              (click)="findDoctorByUuid(doctor?.uuid)">
              {{ doctor.fullName }}
            </li>
          </ul>
        </div>
      </div>
      <span class="small tipo-main-color light" *ngIf="searchDoctor">
        {{ 'PRIVATE.CONSENTS.DATA-CONSENT.MESSAGE-SELECT-DOCTOR' | translate }}
      </span>


  <h5 class="tipo-second-color">
    <fa-icon
      [icon]="fa1"
      class="icono fa-1xl back-blue-color tipo-white-color number"></fa-icon>
    {{ 'PRIVATE.CONSENTS.DATA-CONSENT.SELECT-PATIENT' | translate }}
  </h5>

  <div class="input_row select-patient">
    <div class="input back-light-blue-color inlineflex input2" dir="ltr">
      <input
        type="text"
        formControlName="name"
        placeholder="{{
          'PRIVATE.CONSENTS.DATA-CONSENT.SELECT-PATIENT' | translate
        }}"
        [ngClass]="{ wrong: shouldShowError('name') }" />
      <fa-icon
        [icon]="faAngleDown"
        class="icono tipo-second-color arrow"
        (click)="showWarningPatient()"></fa-icon>
      <ul class="patient_select" *ngIf="showDropdown" class="dropdown-list">
        <li
          [value]="patient.uuid"
          *ngFor="let patient of searchPatients; let i = index"
          (click)="findPatientByUuid(patient?.uuid)">
          {{ patient.fullName }}
        </li>
      </ul>
    </div>

    <button *ngIf="!this.roleService.isQuickapi();"
      class="button tipo-white-color back-blue-color button_newpatient"
      dir="ltr"
      (click)="openNewPatient()">
      <fa-icon [icon]="faUserPlus" class="icono iconono"></fa-icon>
      {{ 'PRIVATE.CONSENTS.DATA-CONSENT.BUTTON-NEW-PATIENT' | translate }}
    </button>
  </div>
  <span class="small tipo-main-color light" *ngIf="searchPatient">
    {{ 'PRIVATE.CONSENTS.DATA-CONSENT.MESSAGE-SELECT-PATIENT' | translate }}
  </span>
  <p
    class="message_error wrong-text"
    *ngIf="this.dataConsent.get('patientUuid')?.errors?.['required'] && isOnSubmit">
    {{
      'PRIVATE.CONSENTS.DATA-CONSENT.FORM-MESSAGES.PATIENT-NOT-SELECTED'
        | translate
    }}
  </p>

  <div class="input_row">
    <div class="input input3">
      <label for="document">
        {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-DOCUMENT' | translate }}
      </label>
      <input type="text" formControlName="document" id="document" />
    </div>
    <div class="input input3">
      <label for="age">
        {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-AGE' | translate }}
      </label>
      <input type="text" formControlName="age" id="age" />
    </div>
    <div class="input input3 last">
      <label for="expedient">
        {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-EXPEDIENT' | translate }}
      </label>
      <input type="text" formControlName="expedient" id="expedient" />
    </div>
  </div>
  <h5 class="tipo-second-color">
    <fa-icon
      [icon]="fa2"
      class="icono fa-1xl back-blue-color tipo-white-color number"></fa-icon>
    {{ 'PRIVATE.CONSENTS.DATA-CONSENT.SELECT-PROCESS' | translate }}
  </h5>
  <div class="input">
    <label for="type" class="type">
      {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-TYPE' | translate }}
    </label>
    <div class="input_column">
      <label class="input input_radio" *ngFor="let surgeryType of surgeryTypes">
        <input
          type="radio"
          formControlName="type"
          [value]="surgeryType.id"
          id="inputr{{ surgeryType.id }}" />
        <label
          [for]="surgeryType.id"
          id="surgerytype_label{{ surgeryType.id }}">
          {{ surgeryType.name }}
        </label>
      </label>
    </div>
  </div>
  <div class="input">
    <label for="usuals">
      {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-USUALS' | translate }}
    </label>
    <select
      type="text"
      formControlName="usuals"
      id="usuals"
      size="8"
      [ngClass]="{ wrong: shouldShowError('usuals') }">
      <option value="0">
        {{ 'PRIVATE.CONSENTS.DATA-CONSENT.DEFAULT-TOP-PROCEDURE' | translate }}
      </option>
      <option *ngFor="let procedure of topProcedures" [value]="procedure.code">
        {{ procedure.name }}
      </option>
    </select>
  </div>
  <div class="input">
    <label for="specialty">
      {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-SPECIALITY' | translate }}
    </label>
    <select
      formControlName="specialty"
      id="specialty"
      [ngClass]="{ wrong: shouldShowError('specialty') }">
      <option value="0">
        {{ 'PRIVATE.CONSENTS.DATA-CONSENT.DEFAULT-SPECIALTY' | translate }}
      </option>
      <option *ngFor="let specialty of specialties" [value]="specialty.id">
        {{ specialty.description }}
      </option>
    </select>
    <p
      class="message_error wrong-text"
      *ngIf="(this.dataConsent.get('specialty')?.errors?.['notEqualToZero'] && this.dataConsent.get('usuals')?.errors?.['notEqualToZero']) && isOnSubmit">
      {{
        'PRIVATE.CONSENTS.DATA-CONSENT.FORM-MESSAGES.SPECIALTY-NOT-SELECTED'
          | translate
      }}
    </p>
  </div>
  <div class="input back-light-blue-color inlineflex input2" dir="ltr" (click)="toggleDropdownProcedure()">
    <input
      type="text"
      formControlName="procedureName"
      placeholder="{{
        'PRIVATE.CONSENTS.DATA-CONSENT.DEFAULT-PROCEDURES' | translate
      }}"
      [ngClass]="{ wrong: shouldShowError('procedure') }" />
    <fa-icon
      [icon]="faAngleDown"
      class="icono tipo-second-color arrow"
      ></fa-icon>
    <ul class="patient_select" *ngIf="showDropdownProcedure" class="dropdown-list">
      <li
        [value]="procedure.code"
        *ngFor="let procedure of filteredProcedures"
        (click)="findProcedureByCode(procedure?.code)">
        {{ procedure.name }}
      </li>
    </ul>
  </div>
  <p
      class="message_error wrong-text"
      *ngIf="(this.dataConsent.get('procedure')?.errors?.['notEqualToZero'] && isOnSubmit)">
      {{
        'PRIVATE.CONSENTS.DATA-CONSENT.FORM-MESSAGES.PROCEDURE-NOT-SELECTED'
          | translate
      }}
    </p>
  <div class="input" *ngIf="hasAdditionalClause">
    <label for="attached">
      {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-ATTACHED' | translate }}
    </label>
    <select formControlName="additionalClause" id="additionalClause">
      <option value="0">
        {{
          'PRIVATE.CONSENTS.DATA-CONSENT.DEFAULT-ADDITIONAL-CLAUSES' | translate
        }}
      </option>
      <option *ngFor="let clause of additionalClauses" [value]="clause.code">
        {{ clause.title }}
      </option>
    </select>
    <div class="clause">
      <p class="small tipo-second-color">
        {{ 'PRIVATE.CONSENTS.DATA-CONSENT.CLAUSE-TITLE' | translate }}
      </p>
      <p
        class="small"
        [innerHTML]="selectedAdditionalClause?.text || '' | safeHtml"></p>
    </div>
  </div>
  <h5 class="tipo-second-color">
    <fa-icon
      [icon]="fa3"
      class="icono fa-1xl back-blue-color tipo-white-color number"></fa-icon>
    {{ 'PRIVATE.CONSENTS.DATA-CONSENT.CONSENT-PERSONALIZATION' | translate }}
  </h5>
  <div class="input input_row">
    <input type="checkbox" formControlName="consent" id="consent" />
    <label
      for="consent"
      id="consent_label"
      [ngClass]="{ isCheckedConsent: isCheckedConsent }"
      (click)="CheckedConsent()">
      {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-CONSENT' | translate }}
    </label>
    <div class="tooltip tooltip_float">
      <fa-icon
        [icon]="faCircleQuestion"
        class="icono tipo-second-color ico_tool"></fa-icon>
      <p class="text_tip small">
        {{ 'PRIVATE.CONSENTS.DATA-CONSENT.TEXT-TIP1' | translate }}
      </p>
    </div>
  </div>
  <div class="input input_row">
    <input type="checkbox" formControlName="inform" id="inform" />
    <label
      for="inform"
      id="inform_label"
      [ngClass]="{ isCheckedInform: isCheckedInform }"
      (click)="CheckedInform()">
      {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-INFORM' | translate }}
    </label>
    <div class="tooltip tooltip_float">
      <fa-icon
        [icon]="faCircleQuestion"
        class="icono tipo-second-color ico_tool"></fa-icon>
      <p class="text_tip small">
        {{ 'PRIVATE.CONSENTS.DATA-CONSENT.TEXT-TIP2' | translate }}
      </p>
    </div>
  </div>
  <div class="input input_row">
    <input type="checkbox" formControlName="covid" id="covid" />
    <label
      for="covid"
      id="covid_label"
      [ngClass]="{ isCheckedCovid: isCheckedCovid }"
      (click)="CheckedCovid()">
      {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-COVID' | translate }}
    </label>
    <div class="tooltip tooltip_float">
      <fa-icon
        [icon]="faCircleQuestion"
        class="icono tipo-second-color ico_tool"></fa-icon>
      <p class="text_tip small">
        {{ 'PRIVATE.CONSENTS.DATA-CONSENT.TEXT-TIP3' | translate }}
      </p>
    </div>
  </div>
  <div class="input">
    <label for="personalization">
      {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-PERSONALIZATION' | translate }}
    </label>
    <textarea
      formControlName="personalization"
      id="personalization"
      rows="5"
      class="regular"></textarea>
  </div>
  <div class="input">
    <label for="observations">
      {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-OBSERVATIONS' | translate }}
    </label>
    <textarea
      formControlName="observations"
      id="observations"
      rows="5"
      class="regular"></textarea>
  </div>
  <div class="input_row">
    <div class="input input2">
      <label for="code1">
        {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-CODE1' | translate }}
      </label>
      <input type="text" formControlName="code1" id="code1" />
      <div class="tooltip tooltip_float">
        <fa-icon
          [icon]="faCircleQuestion"
          class="icono tipo-second-color ico_tool"></fa-icon>
        <p class="text_tip small">
          {{ 'PRIVATE.CONSENTS.DATA-CONSENT.TEXT-TIP-CODE1' | translate }}
        </p>
      </div>
    </div>
    <div class="input input2 last">
      <label for="code2">
        {{ 'PRIVATE.CONSENTS.DATA-CONSENT.INPUT-CODE2' | translate }}
      </label>
      <p class="small" *ngIf="procedure && (procedure.cie10 !=='' && procedure.cie10 !== null)">{{"PRIVATE.CONSENTS.NEW-CONSENT.TEXT-CIE" | translate}} <span class="tipo-second-color bold">{{procedure.cie10}}</span></p>
      <input type="text" formControlName="code2" id="code2" />
      <div class="tooltip tooltip_float">
        <fa-icon
          [icon]="faCircleQuestion"
          class="icono tipo-second-color ico_tool"></fa-icon>
        <p class="text_tip small">
          {{ 'PRIVATE.CONSENTS.DATA-CONSENT.TEXT-TIP-CODE2' | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="input">
    <input
      type="text"
      formControlName="subtitle"
      id="subtitle"
      placeholder="{{
        'PRIVATE.CONSENTS.DATA-CONSENT.SUBTITLE-CONSENT' | translate
      }}" />
  </div>
  <h5 class="tipo-second-color">
    <fa-icon
      [icon]="fa4"
      class="icono fa-1xl back-blue-color tipo-white-color number"></fa-icon>
    {{ 'PRIVATE.CONSENTS.DATA-CONSENT.CONSENT-SIGN' | translate }}
  </h5>
</form>
<!-- Ventana nuevo paciente-->
<div class="window_back" *ngIf="openwindow">
  <div class="window">
    <app-data-patient
      [newPatient]="false"
      [isNewGDPR]="true"
      [openwindow]="true"
      (closeWindowEvent)="closeWindow()"
      (patientUuidEvent)="handleChildPatientUuid($event)"></app-data-patient>
  </div>
</div>

<!-- Ventana -->
<div class="window_back" *ngIf="noAdditionalClause">
  <div class="window">
    <p class="regular">
      {{"PRIVATE.CONSENTS.DATA-CONSENT.MESSAGE-ADDITIONAL-CLAUSE" | translate}}
    </p>
    <div class="buttons">
      <button
        class="button tipo-white-color back-blue-color"
        (click)="continueOnSubmit()">
        <fa-icon [icon]="faHouseMedicalCircleCheck" class="icono"></fa-icon>
       {{"PRIVATE.CONSENTS.DATA-CONSENT.BUTTON-GO-ON" | translate}}
      </button>
      <button
        class="button tipo-main-color back-grey-color"
        (click)="noAdditionalClause = false">
        {{ 'PRIVATE.PATIENT.DATA-PATIENT.BUTTON-CANCEL' | translate }}
      </button>
    </div>
  </div>
</div>
