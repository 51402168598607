import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Filter } from '@app/core/models/front/filters';
import { Pagination } from '@app/core/models/front/pagination';
import { Sorters } from '@app/core/models/front/sorters';
import { ConsentSendToPatientInputDTO } from '@app/core/models/input/consent/Consent-send-to-patient-input.model';
import { Consent } from '@app/core/models/input/consent/consent.model';
import { SendMailToPatient } from '@app/core/models/input/consent/send-email-to-patient.model';
import { Page } from '@app/core/models/input/page/pages.model';
import { CiTemplate } from '@app/core/models/input/template/ci-template.model';
import { ConsentOutputDTO } from '@app/core/models/output/consent/consent-output.model';
import { ReSendDocumentDTO } from '@app/core/models/output/consent/re-send-documentDTO.model';
import { RevokeConsentDTO } from '@app/core/models/output/consent/revoke-consent.model';
import { UpdateConsentFile } from '@app/core/models/output/consent/update-consent-file.model';
import { EnvironmentService } from '@app/environment.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilsService } from '../utils/utils.service';
import { ConsentParametersInputDTO } from '@app/core/models/input/consent-parameters-input.model';
import { DEFAULT_ELCI_LANGUAGE } from '@app/core/constants/Constants';
import { ConsentStatusOutputDTO } from '@app/core/models/input/consent/consent-status-output.model';


@Injectable({
  providedIn: 'root'
})
export class ConsentService {

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private uSrv: UtilsService
  ) { }

  private consentSubject: BehaviorSubject<Consent | null> = new BehaviorSubject<Consent | null>(null);
  consent$: Observable<Consent | null> = this.consentSubject.asObservable();

  getLocalConsent(): Consent | null {
    return this.consentSubject.getValue();
  }

  setConsent(consent: Consent) {
    this.consentSubject.next(consent);
  }

  getConsentInMemory() {
    return this.consentSubject.asObservable();
  }

  getConsents(pagination: Pagination, filters: Filter[], sorters: Sorters): Observable<Page> {
    return this.http.get<Page>(
      `${this.env.apiUrl}/consents?` +
        this.uSrv.genPaginationInUri(pagination)+
        this.uSrv.getFiltersInUri(filters) +
        this.uSrv.genSortersInUri(sorters)
    );
  }

  getConsent(uuid: string): Observable<Consent> {
    return this.http.get<Consent>(`${this.env.apiUrl}/consents/`+uuid);
  }

  getConsentUuidById(id: string): Observable<Consent> {
    return this.http.get<Consent>(`${this.env.apiUrl}/consents/id/`+id);
  }

  newConsent(consentOutput: ConsentOutputDTO) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    return this.http.post<ConsentOutputDTO>(`${this.env.apiUrl}/consents`, JSON.stringify(consentOutput), {headers: headers})
  }

  updateConsent(consentUuid: string , consentOutput: ConsentOutputDTO) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    return this.http.put<ConsentOutputDTO>(`${this.env.apiUrl}/consents/`+consentUuid, JSON.stringify(consentOutput), {headers: headers})
  }

  sendConsentToPatient(consentUuid: string , consentSendToPatientInputDTO: ConsentSendToPatientInputDTO) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    return this.http.patch<ConsentOutputDTO>(`${this.env.apiUrl}/consents/`+consentUuid, JSON.stringify(consentSendToPatientInputDTO), {headers: headers})
  }


  sendConsentToSecondLegalRepresentative(consentUuid: string , reSendDocument: ReSendDocumentDTO) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    return this.http.patch<ConsentOutputDTO>(`${this.env.apiUrl}/consents/${consentUuid}/send-to-second-signer`, JSON.stringify(reSendDocument), {headers: headers})
  }

  revokeConsentToSecondLegalRepresentative(consentUuid: string , reSendDocument: ReSendDocumentDTO) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    return this.http.patch<ConsentOutputDTO>(`${this.env.apiUrl}/consents/${consentUuid}/revoke-second-signer`, JSON.stringify(reSendDocument), {headers: headers})
  }


  getDefaultConsentTemplates(countryCode = DEFAULT_ELCI_LANGUAGE): Observable<CiTemplate> {
    return this.http.get<CiTemplate>(`${this.env.apiUrl}/consents/template-consent/`+countryCode);
  }

  revokeConsent(consentUuid: string, reSendConsentDTO: RevokeConsentDTO) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    return this.http.post<ConsentOutputDTO>(`${this.env.apiUrl}/consents/revoke/`+ consentUuid, JSON.stringify(reSendConsentDTO), {headers: headers})
  }

  ratifyConsent(consentUuid: string, reSendDocumentDTO: ReSendDocumentDTO) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    return this.http.patch<ConsentOutputDTO>(`${this.env.apiUrl}/consents/${consentUuid}/ratify`, JSON.stringify(reSendDocumentDTO), {headers: headers})
  }


  resendConsentExpired(consentUuid: string, reSendDocument: ReSendDocumentDTO) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    return this.http.patch<ConsentOutputDTO>(`${this.env.apiUrl}/consents/${consentUuid}/resend`, JSON.stringify(reSendDocument), {headers: headers})
  }

  updateConsentFile(consentUuid:string, updateConsentFile: UpdateConsentFile) {
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    return this.http.patch<ConsentOutputDTO>(`${this.env.apiUrl}/consents/paper/`+ consentUuid, JSON.stringify(updateConsentFile), {headers: headers})
  }

  sendMailToPatient(consentUuid:string, sendMail:SendMailToPatient){
    const headers = new HttpHeaders({
      'Content-Type':  'application/json'
    })

    return this.http.post<ConsentOutputDTO>(`${this.env.apiUrl}/consents/send-email/` + consentUuid, JSON.stringify(sendMail), {headers: headers})
  }

  deleteConsentByUuid (consentUuid: string){
    return this.http.delete(`${this.env.apiUrl}/consents/${consentUuid}`);
  }

  getConsentParametersToCreate(): Observable<ConsentParametersInputDTO> {
    return this.http.get(`${this.env.apiUrl}/consents/parameters-to-create`);
  }

  getConsentStatus(consentUuid: string): Observable<ConsentStatusOutputDTO> {
    return this.http.get(`${this.env.apiUrl}/consents/`+ consentUuid + `/status`);
  }

}
