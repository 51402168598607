import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Clause } from '@app/core/models/input/procedure/clause.model';
import { Procedure } from '@app/core/models/input/procedure/procedure.model';
import { Specialty } from '@app/core/models/input/specialty.model';
import { UserInput } from '@app/core/models/input/user-input.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsentsShareService {

  private genConsentFormSubject: BehaviorSubject<FormGroup | null> = new BehaviorSubject<FormGroup | null>(null);
  genConsentForm$: Observable<FormGroup | null> = this.genConsentFormSubject.asObservable();

  setGenConsentForm(consent: FormGroup) {
    this.genConsentFormSubject.next(consent);
  }

  private templateSubject: BehaviorSubject<{ templateUuid: string, templateType: string } | null> = new BehaviorSubject<{ templateUuid: string, templateType: string } | null>(null);
  template$: Observable<{ templateUuid: string, templateType: string } | null> = this.templateSubject.asObservable();

  setTemplate(template: { templateUuid: string, templateType: string }) {
    this.templateSubject.next(template);
  }

  private templateLanguageSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  templateLanguage$: Observable<string | null> = this.templateLanguageSubject.asObservable();

  setTemplateLanguage(languageId: string) {
    this.templateLanguageSubject.next(languageId);
  }

  private doctorSubject: BehaviorSubject<UserInput | null> = new BehaviorSubject<UserInput | null>(null);
  doctor$: Observable<UserInput | null> = this.doctorSubject.asObservable();

  setDoctor(doctor: UserInput) {
    this.doctorSubject.next(doctor);
  }

  private selectedProcedureSubject: BehaviorSubject<Procedure | null> = new BehaviorSubject<Procedure | null>(null);
  selectedProcedure$: Observable<Procedure | null> = this.selectedProcedureSubject.asObservable();

  setSelectedProcedure(selectedProcedure: Procedure | null) {
    this.selectedProcedureSubject.next(selectedProcedure);
  }

  private selectedAdditionalClauseSubject: BehaviorSubject<Clause | null> = new BehaviorSubject<Clause | null>(null);
  selectedAdditionalClause$: Observable<Clause | null> = this.selectedAdditionalClauseSubject.asObservable();

  setSelectedAdditionalClause(selectedAdditionalClause: Clause | null) {
    this.selectedAdditionalClauseSubject.next(selectedAdditionalClause);
  }

  private selectedOutputTypeSubject: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  selectedOutputType$: Observable<number | null> = this.selectedOutputTypeSubject.asObservable();

  setSelectedOutputType(selectedOutputType: number | null) {
    this.selectedOutputTypeSubject.next(selectedOutputType);
  }

  private deliveryDateSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  deliveryDate$: Observable<string | null> = this.deliveryDateSubject.asObservable();

  setDeliveryDate(deliveryDate: string | null) {
    this.deliveryDateSubject.next(deliveryDate);
  }

  private _selectedSpecialty?: Specialty;

  public setSelectedSpecialty(specialty: Specialty) {
    this._selectedSpecialty = specialty;
  }

  public getSelectedSpecialty(): Specialty | undefined {
    return this._selectedSpecialty;
  }


}
