<div class="wrapper">   
    <div class="list">
        <h4 class="title tipo-second-color bold" id="title_table">{{'SUPER-ADMIN.LIST-PATIENTS.TITLE-PATIENTS' | translate}}</h4>
        <table class="table" aria-describedby="title_table">
            <thead class="tipo-second-color bold">
               
                <th scope="col" class="company_name">
                    <div class="column">                        
                        <span>{{'SUPER-ADMIN.LIST-ADMINS.DATA-COMPANY' | translate}}</span>    
                        <input type="text" name="filterBussinessName" (debounceInput)="addFilter('businessName', filterBussinessName.value)" [appDebounceInput]="300" #filterBussinessName id="filterBussinessName" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>
                <th scope="col" class="name">
                    <div class="column">                        
                         <span>{{'SUPER-ADMIN.LIST-PATIENTS.DATA-NAME' | translate}}</span>   
                        <input type="text" name="filterName" (debounceInput)="addFilter('name', filterName.value)" [appDebounceInput]="300" #filterName id="filterName" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>
                <th scope="col" class="surname">
                    <div class="column">                        
                         <span>{{'SUPER-ADMIN.LIST-PATIENTS.DATA-SURNAME' | translate}}</span>   
                        <input type="text" name="filterFullName" (debounceInput)="addFilter(searchNameFor, filterFullName.value)" [appDebounceInput]="300" #filterFullName id="filterFirtLastName" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>
                <th scope="col" class="document">
                    <div class="column">                               
                         <span>{{'SUPER-ADMIN.LIST-PATIENTS.DATA-DOCUMENT' | translate}}</span>   
                        <input type="text" name="filterDocument" (debounceInput)="addFilter(searchDocumentFor, filterDocument.value)" [appDebounceInput]="300" #filterDocument id="filterDocument" placeholder="{{'PRIVATE.PATIENT.LIST-PATIENTS.FILTER' | translate }}">
                    </div>
                </th>
                <th scope="col" class="date_on">
                    <div class="column">                    
                        <div class="row">
                            <span>{{ "PRIVATE.PATIENT.LIST-PATIENTS.TITLE-DATE" | translate}}</span>
                            <div class="column arrows">
                                <fa-icon [icon]="faCaretUp" class="icono fa-xl up" (click)="sortPatients('creationDate', 'asc')"></fa-icon>
                                <fa-icon [icon]="faCaretDown" class="icono fa-xl down" (click)="sortPatients('creationDate', 'desc')"></fa-icon>
                            </div>
                        </div>
                    </div>
                </th>
                <th scope="col" class="email"><span>{{'PRIVATE.ADMINISTRATOR.DATA-ADMINISTRATOR.EMAIL' | translate}}</span> </th>
                <th scope="col" class="history"><span>{{'SUPER-ADMIN.LIST-PATIENTS.DATA-HISTORY' | translate}}</span> </th>
                <th scope="col" class="rl">
                    <span>{{'SUPER-ADMIN.LIST-PATIENTS.DATA-RLS' | translate}}</span>
                    <div class="tooltip">
                        <label for="searchRls" id="checkrlslabel" 
                        [ngClass]="{'isCheckedRls':isCheckedRls}" 
                        (click)="checkedRls()"
                        ></label>                       
                        <input type="checkbox" name="searchRls" id="checkrls"> 
                        <p class="text_tip small light">{{"PRIVATE.PATIENT.LIST-PATIENTS.FILTER-RLS" | translate}}</p>
                   </div>
                </th>
                <th scope="col" class="state">
                    <div class="column">
                    <span>{{ 'PRIVATE.CONSENTS.LIST-CONSENTS.LABEL-STATE' | translate }}</span>                    
                    </div>
                </th>
            </thead>
            <tbody>
                <tr [routerLink]="['./'+ patient.uuid + '/file']" *ngFor= "let patient of patients; let i=index">               
                <td class="company_name">{{patient.businessName}}</td>
                <td class="name"> {{patient.name}}</td>
                <td class="surname">{{patient.firstLastName}} {{patient.secondLastName}}</td>
                <td class="document" >{{patient.documentNumber}}</td>                             
                <td class="date_on" >{{patient.creationDate | date : 'dd/MM/YYYY'}}</td>
                <td class="email" >{{patient.email}}</td>
                <td class="history" >{{patient.healthHistoryExt}}</td>
                <td class="rl" >{{patient.representativePatients?.length}}</td>
                <td class="state" >{{patient.isActive === true ? "Activo" : "Inactivo"}}</td> 
                </tr>
            </tbody>
        </table>
        <!-- Pagination -->
        <app-paginator
        [totalElements]="page?.totalElements ?? 0"  
        [numbersOfElements]="page?.numberOfElements ?? 0"
        [totalPages]="page?.totalPages ?? 0"
        [pageNumber]="pagination.page"
        [lastPage]="page?.last ?? false"
        (paginate)="paginar($event)"></app-paginator>
    <!-- End of Pagination -->
</div>    

